import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { tap } from "rxjs/operators";
import { AuthService } from "../services/auth/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private _authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._authService.isLoggedIn$
    .pipe(tap(allowed => {
      if (!allowed) {
        this.router.navigate(["/sessions/signin"], {
          queryParams: {
            return: state.url
          }
        });
      }
    }));
}
}
