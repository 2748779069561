import { environment } from "../../../environments/environment";
import { ApiError } from "../models/shared/error.model";

const API_BASE_URL = environment.apiURL;
const KARZA_API = environment.KarzaURL;
const API_BASE_DOMAIN = environment.domain
export const API_ENDPOINTS = {
  Base: {
    base: API_BASE_URL,
  },
  Auth: {
    login: API_BASE_URL + "/auth/login",
    profile: API_BASE_URL + "/auth/profile",
  },
  state: {
    list: API_BASE_URL + "/state/list",
    base: API_BASE_URL + "/state",
  },
  loanType: {
    list: API_BASE_URL + "/LoanType/list",
    base: API_BASE_URL + "/LoanType",
    drpList: API_BASE_URL + "/LoanType/drplist",
  },
  kycDocuments: {
    list: API_BASE_URL + "/KYCDocumentType/list",
    base: API_BASE_URL + "/KYCDocumentType",
    drpList: API_BASE_URL + "/KYCDocumentType/drplist",
  },
  paymentMode: {
    list: API_BASE_URL + "/PaymentMode/list",
    base: API_BASE_URL + "/PaymentMode",
    drpList: API_BASE_URL + "/PaymentMode/drplist",
  },
  user: {
    list: API_BASE_URL + "/User/list",
    base: API_BASE_URL + "/User",
    drpList: API_BASE_URL + "/User/drplist",
    changePassword: API_BASE_URL + "/User/changePassword",
    forgotPasswordSendOtp: API_BASE_URL + "/User/forgotPassword",
    passwordChange: API_BASE_URL + "/User/forgotPasswordChange",
  },
  multiusers: {
    list: API_BASE_URL + "/User/list",
    base: API_BASE_URL + "/User",
    drpList: API_BASE_URL + "/User/drplist",
    changePassword: API_BASE_URL + "/User/changePassword",
    forgotPasswordSendOtp: API_BASE_URL + "/User/forgotPassword",
    passwordChange: API_BASE_URL + "/User/forgotPasswordChange",
  },
  customer: {
    list: API_BASE_URL + "/Customer/list",
    base: API_BASE_URL + "/Customer",
    // drpList: API_BASE_URL + "/",
  },
  pinCode: {
    list: API_BASE_URL + "/PinCode/list",
    base: API_BASE_URL + "/PinCode",
    drpList: API_BASE_URL + "/PinCode/drplist",
  },
  city: {
    list: API_BASE_URL + "/City/list",
    base: API_BASE_URL + "/City",
    drpList: API_BASE_URL + "/City/drplist",
  },
  product: {
    list: API_BASE_URL + "/Product/list",
    base: API_BASE_URL + "/Product/",
  },
  store: {
    list: API_BASE_URL + "/Store/list",
    base: API_BASE_URL + "/Store",
  },
  depositedocumenttypes: {
    list: API_BASE_URL + "/DepositDocumentType/list",
    base: API_BASE_URL + "/DepositDocumentType",
  },
  relation: {
    list: API_BASE_URL + "/Relation/list",
    base: API_BASE_URL + "/Relation",
  },
  paymentreceipttypes: {
    list: API_BASE_URL + "/PaymentReceiptType/list",
    base: API_BASE_URL + "/PaymentReceiptType",
  },
  roles: {
    list: API_BASE_URL + "/role/list",
    base: API_BASE_URL + "/role",
    features: API_BASE_URL + "/RoleFeature",
  },
  district: {
    list: API_BASE_URL + "/district/list",
    base: API_BASE_URL + "/district",
  },
  Dropdownlist: {
    role: API_BASE_URL + "/role/list",
    features: API_BASE_URL + "/Feature/drplist",
    activity: API_BASE_URL + "/Activity/drplist",
    base: API_BASE_URL + "/role",
    documentReciptType: API_BASE_URL + "/PaymentReceiptType/drplist",
    documentTypeName: API_BASE_URL + "/DepositeDocumentType/list",
    paymentMode: API_BASE_URL + "/PaymentMode/drplist",
    relation: API_BASE_URL + "/Relation/drplist",
    loanType: API_BASE_URL + "/LoanType/drplist",
    documentTypes: API_BASE_URL + "/DepositDocumentType/drplist",
    kycDocType: API_BASE_URL + "/KYCDocumentType/drplist",
  },
  featureActivity: {
    list: API_BASE_URL + "/FeatureActivity/List",
    base: API_BASE_URL + "/role",
  },
  document: {
    base: API_BASE_URL + "/Document/Upload",
  },

  erpMaster: {
    company: API_BASE_URL + "/ERPMaster/companyList",
    plant: API_BASE_URL + "/ERPMaster/plantList",
    store: API_BASE_URL + "/ERPMaster/storeList",
    product: API_BASE_URL + "/ERPMaster/productList",
    productDetails: API_BASE_URL + "/ERPMaster/productGet",
    salesOrderPost: API_BASE_URL + "/ERPMaster/SalesOrderPost/",
    accountList: API_BASE_URL + "/ERPMaster/AccountList/",
    PaymentPost: API_BASE_URL + "/ERPMaster/PaymentPost/",
    cashSales: API_BASE_URL + "/ERPMaster/CashSalesOrderPost/",
    erpUser: API_BASE_URL + "/ERPMaster/SalesExecutiveList/",
    AdvancePaymentPost: API_BASE_URL + "/ERPMaster/AdvancePaymentPost/",
  },
  userpincodesMapping: {
    list: API_BASE_URL + "/UserPinCode/list",
    base: API_BASE_URL + "/UserPinCode/PinCode",
    export:API_BASE_URL + "/UserPinCode/ExportToExcel",
    import:API_BASE_URL + "/UserPinCode/Import",
  },
  storepincodesMapping: {
    list: API_BASE_URL + "/StorePinCode/list",
    base: API_BASE_URL + "/StorePinCode/PinCode",
    export:API_BASE_URL + "/StorePinCode/ExportToExcel",
    import:API_BASE_URL + "/StorePinCode/Import",
  },
  approvalWorkflow: {
    list: API_BASE_URL + "/Workflow/list",
    base: API_BASE_URL + "/Workflow",
    // drpList: API_BASE_URL + "/Workflow/drplist",
  },

  inquiries: {
    list: API_BASE_URL + "/Inquiry/list",
    base: API_BASE_URL + "/Inquiry",
    drpList: "/Inquiry/drplist",
  },

  tola: {
    list: API_BASE_URL + "/Tola/list",
    base: API_BASE_URL + "/Tola",
    tolaApprove: API_BASE_URL + "/Tola/TolaApprovalReject",
    tolaSendBack: API_BASE_URL + "/Tola/TolaSendBack",
    tolaRejecte: API_BASE_URL + "/Tola/TolaApprovalReject",
    tolaAgreement: API_BASE_URL + "/Tola/TolaAgreementPrint",
    tolaSummary: API_BASE_URL + "/Tola/TolaSummaryDetailPrint",
    tolaAgreementUpload: API_BASE_URL + "/Tola/TolaAgreementUpload",
    tolaDetailDelivery: API_BASE_URL + "/TolaDetail/TolaDetailDelivery",
    tolaDetailForeClose: API_BASE_URL + "/TolaDetail/TolaDetailForeClose",
    inquiryToTola: API_BASE_URL + "/Inquiry/InquiryToTola/",
    tolaDetails: API_BASE_URL + "/TolaDetail",
    customerHistory: API_BASE_URL + "/TolaDetail/CustomerHistoryList",
    getCustomerHistory: API_BASE_URL + "/TolaDetail/CustomerHistoryDetail",

    customerApprove: API_BASE_URL + "/Tola/tolaDetailApprovalReject",
    customerSendBack: API_BASE_URL + "/Tola/tolaDetailApprovalReject",
    customerRejecte: API_BASE_URL + "/Tola/tolaDetailApprovalReject",

    customerEMICard: API_BASE_URL + "/TolaDetail/TolaDetailEMICardPrint",
    drpList: "/Tola/drplist",
    import: API_BASE_URL + "/Tola/ASE/Import",
    sampleFile: API_BASE_DOMAIN + "SampleFile/TolaASE.xlsx"
  },
  customerHistory: {
    list: API_BASE_URL + "/customerHistory/list",
    base: API_BASE_URL + "/customerHistory",
  },

  collection: {
    list: API_BASE_URL + "/Collection/list",
    base: API_BASE_URL + "/Collection",
    print: API_BASE_URL + "/Collection/CollectionPrint",
    collectionDetails: API_BASE_URL + "/Collection/PendingCollectionDetailGet",
    // collectionDetail: API_BASE_URL + "/Collection/PendingCollectionDetailGet",
    collectionCustomer: API_BASE_URL + "/Collection/CollectionCustomerList",
    pendingCollection: API_BASE_URL + "/Collection/PendingCollectionList",
    collectionApproval: API_BASE_URL + "/Collection/CollectionApprovalReject",
    sendBack: API_BASE_URL + "/Collection/CollectionSendBack",
    bulkApprove : API_BASE_URL + "/Collection/CollectionsApprovalReject",
    bulkSendBack: API_BASE_URL + "/Collection/CollectionsSendBack",
  },

  deposit: {
    list: API_BASE_URL + "/Deposit/list",
    base: API_BASE_URL + "/Deposit",
    depositApproval: API_BASE_URL + "/Deposit/DepositApprovalReject",
    sendBack: API_BASE_URL + "/Deposit/DepositSendBack",
  },

  processingFees: {
    list: API_BASE_URL + "/ProcessingFeesConfiguration/list",
    base: API_BASE_URL + "/ProcessingFeesConfiguration",
  },
  collectionincentive: {
    list: API_BASE_URL + "/CollectionIncentiveIntegration/list",
    base: API_BASE_URL + "/CollectionIncentiveIntegration",
  },
  amincentive: {
    list: API_BASE_URL + "/TargetIncentiveIntegration/list",
    base: API_BASE_URL + "/TargetIncentiveIntegration",
  },
  incentivecollection: {
    list: API_BASE_URL + "/ProductIncentiveIntegration/list",
    base: API_BASE_URL + "/ProductIncentiveIntegration",
    export:API_BASE_URL + "/ProductIncentiveIntegration/export",
    import:API_BASE_URL + "/ProductIncentiveIntegration/import",
    download:API_BASE_URL + "/ProductIncentiveIntegration/Get/export"
  },
  foreclose: {
    // list: API_BASE_URL + "/TolaDetail/CustomerHistoryList",
    // base: API_BASE_URL + "/TolaDetail",
    list: API_BASE_URL + "/foreclose/list",
    base: API_BASE_URL + "/Foreclose/CustomerHistoryDetail",
    foreclose: API_BASE_URL + "/TolaDetail/TolaDetailForeClose",
  },
  salestargetentries: {
    base: API_BASE_URL + "/SalesTarget",
    list: API_BASE_URL + "/SalesTarget/list",
    salestargetverify: API_BASE_URL + "/SalesTarget/SalesTargetVerify",
    exporttoexcel: API_BASE_URL + "/SalesTarget/ExportToExcel",
  },
  dashboard: {
    base: API_BASE_URL + "/Dashboard/Details",
  },

  cashSales: {
    list: API_BASE_URL + "/CashSales/list",
    base: API_BASE_URL + "/CashSales",
    approval: API_BASE_URL + "/CashSales/CashSalesApprovalReject",
    postOrder: API_BASE_URL + "/ERPMaster/CashSalesOrderPost/",
    update: API_BASE_URL + "/CashSales",
    delivery: API_BASE_URL + "/CashSales/CashSalesDelivery",
  },

  report: {
    collection: API_BASE_URL + "/Report/collection/",
    customerInquiry: API_BASE_URL + "/Report/customerInquiry/",
    tola: API_BASE_URL + "/Report/tola/",
    deposit: API_BASE_URL + "/Report/Deposit/",
    foreclosed: API_BASE_URL + "/Report/foreclosed/",
    targetvsachievement: API_BASE_URL + "/Report/targetAchievement/",
    cashSales: API_BASE_URL + "/Report/cashSales/",
    disbursement: API_BASE_URL + "/Report/disbursement/",
    attendance: API_BASE_URL + "/Report/Attendance/",
    collectiondonebutnotapproved: API_BASE_URL + "/Report/collectionDetail/",
    collectionIncentive:API_BASE_URL + "/Report/CollectionIncentive/",
    ProductIncentive: API_BASE_URL + "/Report/ProductIncentive/",
    tagetIncentive:API_BASE_URL + "/Report/ProductTargetIncentive/"
  },
  karza: {
    KYCOCR: KARZA_API + "/v3/ocr/kyc",
    pan: KARZA_API + "/v2/pan",
    aadhaar: KARZA_API + "/v2/aadhaar-consent",
  },
};
