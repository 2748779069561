export type operator = "in" | "eq" | "ne" | "gt" | "gte" | "lt" | "lte" | "startswith" | "endswith" | "contains";

export interface IFilterRule {
  field?: string;
  operator?: operator;
  value?: any;
  condition?: "and" | "or" | "startswith";
  rules?: IFilterRule[];
}

export interface IFilterConditions {
  condition: "and" | "or";
  rules: IFilterRule[];
}

export class FilterConditions implements IFilterConditions {
  condition: "and" | "or";
  rules: IFilterRule[];

  constructor() {
    this.condition = "and";
    this.rules = [];
  }
}
