import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "approval",
})
export class ApprovalPipe implements PipeTransform {
  transform(value: any, type?: string) {
    if (value.isCompleted) {
      return "Completed";
    } else if (value.isForeClose) {
      return "ForeClose";
    } else if (value.isDelivered) {
      return "Delivered";
    } else {
      let approvalStatus: string;
      if (value.status) {
        switch (value.status.toString()) {
          case "00":
            approvalStatus = "Pending for Submission";
            break;
          case "01":
            approvalStatus = "In Progress";
            break;
          case "02":
            approvalStatus = "Send Back";
            break;
          case "03":
            approvalStatus = "Rejected";
            break;
          case "99":
            approvalStatus = "System Approved";
            break;
          case "100":
            approvalStatus = "Approved";
            break;
        }
      }
      if (value.otherStatusName) {
        approvalStatus = value.otherStatusName;
      }
      return approvalStatus;
    }
  }
}
