<div id="page-content">
  <mat-card>
    <lms-page-header [title]="title"></lms-page-header>

    <div class="user-image">
      <div class="upload-image">
        <div class="image">
          <img *ngIf="user.imageUploadPath" class="uploaded-image" [src]="user.imageUploadPath" />
          <img *ngIf="!user.imageUploadPath" class="uploaded-image" [src]="userImage" />
        </div>
      </div>
    </div>
    <div class="profile-title text-center">
      <div class="main-title">
        <h1>Name : &nbsp;{{ user.name }}</h1>
      </div>
      <div class="main-title">
        <h2>Role : &nbsp;{{ user.roles[0]?.roleName }}</h2>
      </div>

      <div class="wrapper">
        <h4>Mobile:&nbsp;{{ user.mobileNo }}</h4>
      </div>
      <div *ngIf="user?.emailId" class="wrapper">
        <h4>Email Id: &nbsp; {{ user.emailId }}</h4>
      </div>
    </div>

    <lms-page-header [title]="subTitle"></lms-page-header>

    <div class="lms-form">
      <div class="field-col">
        <!-- User Name (Hindi) -->
        <div class="lms-field-group-wrapper">
          <div class="label-wrapper">
            <mat-label>Name (Hindi)</mat-label>
          </div>
          <div class="field-wrapper">
            <p>{{ user.nameHi }}</p>
          </div>
        </div>
        <!-- User Address -->
        <div class="lms-field-group-wrapper">
          <div class="label-wrapper">
            <mat-label>Address</mat-label>
          </div>
          <div class="field-wrapper">
            <p>{{ user.address }}</p>
          </div>
        </div>

        <div class="lms-field-group-wrapper">
          <div class="label-wrapper">
            <mat-label>Pincode </mat-label>
          </div>
          <div class="field-wrapper">
            <p>{{ user.pincode }}</p>
          </div>
        </div>

        <!-- District -->
        <div class="lms-field-group-wrapper">
          <div class="label-wrapper">
            <mat-label>District</mat-label>
          </div>
          <div class="field-wrapper">
            <p>{{ user.districtName }}</p>
          </div>
        </div>

        <!-- City -->
        <div class="lms-field-group-wrapper">
          <div class="label-wrapper">
            <mat-label>City</mat-label>
          </div>
          <div class="field-wrapper">
            <p>{{ user.cityName }}</p>
          </div>
        </div>

        <!-- State -->
        <div class="lms-field-group-wrapper">
          <div class="label-wrapper">
            <mat-label>State</mat-label>
          </div>
          <div class="field-wrapper">
            <p>{{ user.stateName }}</p>
          </div>
        </div>
      </div>
      <div class="field-col">
        <!-- City -->
        <div class="lms-field-group-wrapper">
          <div class="label-wrapper">
            <mat-label>Reporting Manager</mat-label>
          </div>
          <div class="field-wrapper">
            <p>{{ user.reportingManagerName }}</p>
          </div>
        </div>

        <!-- Is Admin -->
        <div class="lms-field-group-wrapper">
          <div class="label-wrapper">
            <mat-label>Is Admin</mat-label>
          </div>
          <div class="field-wrapper form-wrapper field-wrapper">
            <mat-checkbox [formControl]="isAdmin"> </mat-checkbox>
          </div>
        </div>

        <div class="user-store">
          <h1>User Stores</h1>
        </div>

        <div class="store-table">
          <table mat-table [dataSource]="dataSource" class="lms-table">
            <ng-container matColumnDef="stores">
              <th mat-header-cell *matHeaderCellDef>Store Name</th>
              <td mat-cell *matCellDef="let element">{{ element.storeName }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="7">{{ loadingMessage }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </mat-card>
</div>
