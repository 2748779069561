import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ALERTS_CONFIG } from 'app/shared/config/alerts.config';
import { IAppAlert } from 'app/shared/models/shared/alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  private config = ALERTS_CONFIG;
  constructor(private sb: MatSnackBar) {}

  public raiseAlert(alert: IAppAlert): void {
    var action: string;
    action = alert.canDismiss ? "Dismiss" : "";
    this.sb.open(alert.message, action, {
      // duration: this.config.duration,
      verticalPosition: this.config.verticalPosition,
      horizontalPosition: this.config.horizontalPosition,
    });
  }

  public raiseErrorAlert(message: string) {
    var action: string = "";
    this.sb.open(message, action, {
      duration: this.config.duration,
      verticalPosition: this.config.verticalPosition,
      horizontalPosition: this.config.horizontalPosition,
    });
  }

  public raiseSuccessAlert(message: string) {
    var action: string = "";
    this.sb.open(message, action, {
      duration: this.config.duration,
      verticalPosition: this.config.verticalPosition,
      horizontalPosition: this.config.horizontalPosition,
    });
  }

  public handleError(err: any) {
    if (err && err.message) {
      this.raiseErrorAlert(err.message);
    } else {
      this.raiseErrorAlert("Cannot process request. Contact system administrator");
    }
  }
}
