import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { ChangepasswordComponent } from "./views/changepassword/changepassword.component";
import { ProfilePageComponent } from "./views/profile-page/profile-page.component";

export const rootRouterConfig: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: () => import("./views/sessions/sessions.module").then((m) => m.SessionsModule),
        data: { title: "Session" },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "home",
        loadChildren: () => import("./views/cms/cms.module").then((m) => m.CmsModule),
        data: { title: "Home" },
      },
      {
        path: "masters",
        loadChildren: () => import("./views/modules/masters/masters.module").then((m) => m.MastersModule),
        data: { title: "Masters" },
      },
      {
        path: "report",
        loadChildren: () => import("./views/modules/reports/reports.module").then((m) => m.ReportsModule),
        data: { title: "Reports" },
      },
      {
        path: "settings",
        loadChildren: () => import("./views/modules/settings/settings.module").then((m) => m.SettingsModule),
        data: { title: "Settings" },
      },
      {
        path: "profile",
        component: ProfilePageComponent,
        data: { title: "Profile" },
      },
      {
        path: "changepassword",
        component: ChangepasswordComponent,
        data: { title: "Change Password" },
      },
      {
        path: "transactions",
        loadChildren: () =>
          import("./views/modules/transactions/transactions.module").then((m) => m.TransactionsModule),
        data: { title: "Transactions" },
      },
      {
        path: "reports",
        loadChildren: () => import("./views/modules/reports/reports.module").then((m) => m.ReportsModule),
        data: { title: "Reports" },
      },
      {
        path: "configuration",
        loadChildren: () =>
          import("./views/modules/configuration/configuration.module").then((m) => m.ConfigurationModule),
        data: { title: "Configurations" },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
];
