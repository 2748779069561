import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ProgressBarService } from "./progress-bar.service";

@Component({
  selector: "lms-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  bufferValue: number;
  mode: "determinate" | "indeterminate" | "buffer" | "query";
  value: number;
  visible: boolean;

  // Private
  private destroy$: Subject<any>;

  /**
   * Constructor
   *
   * @param {ProgressBarService} _lmsPBService
   */
  constructor(private _lmsPBService: ProgressBarService) {
    // Set the defaults

    // Set the private defaults
    this.visible = false;
    this.destroy$ = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the progress bar service properties

    // Buffer value
    this._lmsPBService.bufferValue
      .pipe(takeUntil(this.destroy$))
      .subscribe((bufferValue) => {
        this.bufferValue = bufferValue;
      });

    // Mode
    this._lmsPBService.mode.pipe(takeUntil(this.destroy$)).subscribe((mode) => {
      this.mode = mode;
    });

    // Value
    this._lmsPBService.value
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.value = value;
      });

    // Visible
    this._lmsPBService.visible
      .pipe(takeUntil(this.destroy$))
      .subscribe((visible) => {
        this.visible = visible;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
