import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExcerptPipe } from "./excerpt.pipe";
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { TrueyesPipe } from './trueyes.pipe';
import { ApprovalPipe } from './approval.pipe';

const pipes = [
  ExcerptPipe,
  GetValueByKeyPipe,
  TrueyesPipe,
  ApprovalPipe
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule {}