import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { API_ENDPOINTS } from "app/shared/config/api-endpoints.config";
import { IUserProfileDto } from "app/shared/models/auth/user.profile.interface";
import { IUserDto, UserDto } from "app/shared/models/masters";
import { AlertsService } from "app/shared/services/alerts/alerts.service";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { AuthService } from "app/shared/services/auth/auth.service";
import { HttpService } from "app/shared/services/http/http.service";
import { Subject } from "rxjs";

@Component({
  selector: "lms-changepassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.scss"],
})
export class ChangepasswordComponent implements OnInit, OnDestroy {
  title: string;
  apiEndPoint: string;
  user: IUserDto;
  mode: string;

  // Form
  userPasswordForm: FormGroup;

  // Subject
  destroy$: Subject<any>;

  constructor(
    private authService: AuthService,
    private _fb: FormBuilder,
    private _dataService: HttpService,
    private _alertService: AlertsService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _confirmService: AppConfirmService
  ) {
    this.title = "Change Password";
    this.user = new UserDto();
    this.destroy$ = new Subject();
  }

  //#region public form getter
  get f() {
    return this.userPasswordForm.controls;
  }
  //#endregion public form getter

  ngOnInit(): void {
    let id: number = 0;
    this.authService.userProfile$.subscribe((res) => {
      this.user.id = res.id;
    });

    this.userPasswordForm = this._initForm(this.user);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // #region Public Methods
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update State
   * @returns : with form validation
   */

  public changepassword() {
    if (this.userPasswordForm.invalid) {
      let errorMsg = this._getFormError();
      if (errorMsg) {
        this._alertService.raiseErrorAlert(errorMsg);
        this.f.value.reset();
        return;
      } else {
        this._alertService.raiseErrorAlert("Please correct all errors in the form.");
        return;
      }
    }
    this._dataService.addData(this.userPasswordForm.value, API_ENDPOINTS.user.changePassword).subscribe(
      (res) => {
        if (res && res.success) {
          this._alertService.raiseSuccessAlert(`Password chagned successfully`);
          this._router.navigate(["/home"]);
        } else {
          if (res.alerts.length > 0) this._alertService.raiseErrorAlert(res.alerts[0].message);
          //this._alertService.raiseErrorAlert(res.message);
        }
      },
      (err) => {
        this._handleError(err);
      }
    );
  }

  //#endregion Public Methods

  //#region Private Methods
  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Init form
   * @param data : CityDto
   * @param mode : Add | Edit | View
   * @returns : Form Group
   */
  private _initForm(data: IUserDto): FormGroup {
    let form: FormGroup = this._fb.group({
      oldPassword: ["", [Validators.required, Validators.maxLength(256)]],
      newPassword: ["", [Validators.required, Validators.maxLength(256)]],
    });

    if (data) {
      form.patchValue(data);
    }

    return form;
  }
  /**
   * Get Form Error
   * @returns : Form Error
   */
  private _getFormError(): string {
    if (this.f.oldPassword.errors?.required) {
      return "old password is required";
    }

    if (this.f.oldPassword.errors?.maxlength) {
      return "old password should not be more than 256 characters";
    }

    if (this.f.newPassword.errors?.required) {
      return "Password is required";
    }

    if (this.f.newPassword.errors?.maxlength) {
      return "password should not be more than 256 characters";
    }

    if (this.f.confirmpassword.errors?.required) {
      return "confirm password is required";
    }

    if (this.f.confirmpassword.errors?.maxlength) {
      return "confirm password should not be more than 256 characters";
    }
  }

  /**
   * Handle Error
   * @param err
   */
  private _handleError(err: any) {
    if (err && err.message) {
      this._alertService.raiseErrorAlert(err.message);
    } else {
      this._alertService.raiseErrorAlert("Cannot process request. Contact system administrator");
    }
  }
  //#endregion Private Methods
}
