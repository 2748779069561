import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "environments/environment";
import { IAppAlert } from "../models/shared/alert.model";
import { ProgressBarService } from "../components/progress-bar/progress-bar.service";
import { AlertsService } from "../services/alerts/alerts.service";

/*
 * Api Response interceptor will check all incoming resoponses and centrally manage the errors and alerts
 * so as to avoid handling errors and alerts in each service and service calls
 */

@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {
  private alert: IAppAlert;

  constructor(private pbService: ProgressBarService, private alertService: AlertsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.pbService.show();
    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          this.pbService.hide();
          if (req.url.startsWith(environment.apiURL) && evt.body) {
            let resp = evt.body;
            if (resp.success == false) {
              if (resp.alerts && resp.alerts[0]) {
                this.alert = resp.alerts[0];
              } else {
                this.alert = {
                  code: resp.resCode,
                  message: resp.message,
                  canDismiss: true,
                  autoClose: false,
                  type: "error",
                  level: "page",
                };
              }
              this.alertService.raiseAlert(this.alert);
            } else {
              //Raise generic error
            }
          }
        }
      })
    );
  }
}
