<div class="popupbox">

  <!-- page Header -->
  <div id="page-header">
    <lms-page-header [title]="title" [pageActions]="pageActions"></lms-page-header>
  </div>


  <div id="page-root" fxLayout="column" class="list-tbl">
    <div class="wrapper" id="page-content">
      <!-- Table -->
      <table mat-table [dataSource]="dataSource" class="lms-table" #agentTable="matSort" matTableFilter matSort
        MatSortActive="name" matSortDirection="asc" (matSortChange)="sortData($event)">
        <!-- ng-container -->
        <ng-container [matColumnDef]="column.id" *ngFor="let column of columnsDef">
          <th mat-header-cell *matHeaderCellDef>
            <!-- Column Name With Sorting -->
            <div class="col-name" *ngIf="column?.controls" fxLayout="row" mat-sort-header>
              {{ column.name }}
            </div>

            <!-- Column Name Without Sorting  -->
            <div *ngIf="!column?.controls">
              {{ column.name }}
            </div>

            <div *ngIf="column?.type == 'number'">
              <input type="number" class="inlinesearch" [formControl]="column?.controls" />
            </div>

            <!-- Filter FormControl -->
            <div *ngIf="column?.type == 'text'">
              <input name="code" class="inlinesearch" [formControl]="column?.controls" />
            </div>

            <!-- Filter FormControl with DropDown -->
            <div *ngIf="column?.type == 'boolean' && column?.controls">
              <mat-select [formControl]="column?.controls" class="inlinesearch">
                <mat-option value="">-Select-</mat-option>
                <mat-option value="true">Yes</mat-option>
                <mat-option value="false">No</mat-option>
              </mat-select>
            </div>

            <!-- Approval Status -->
            <div *ngIf="column?.type == 'approvalStatus' && column?.controls">
              <mat-select [formControl]="column?.controls" class="inlinesearch">
                <mat-option value="00">Pending For Submission</mat-option>
              </mat-select>
            </div>
          </th>

          <td mat-cell *matCellDef="let element; let i = index">
            <!-- Data with True Yes Pipe -->
            <p *ngIf="column.type == 'boolean'">{{ element[column.id] | trueyes }}</p>

            <!-- Type : Number -->
            <p *ngIf="column.type == 'number'">{{ element[column.id] }}</p>

            <p *ngIf="column.type == 'approvalStatus'">
              {{ element[column.id] | approval }}
            </p>
            <p *ngIf="column.type == 'date'">
              {{ element[column.id] | date: 'dd/MM/YYYY' }}
            </p>

            <!-- Type : Text -->
            <p *ngIf="column.type == 'text' && !column.addArrayfield">{{ element[column.id] }}</p>

            <!--Only For executive (user) Role Name-->
            <div *ngIf="column.type == 'text' && column.addArrayfield">
              <p *ngFor="let ele of element[column.id]">{{ele[column.addArrayfield] }}</p>
            </div>


            <div *ngIf="column.type == 'radio'" class="text-center">
              <mat-radio-button [value]="i" [checked]="selected === i" (focusout)="valueChange(i)"> </mat-radio-button>
            </div>

            <div *ngIf="column.type == 'checkbox'" class="text-center">
              <input type="checkbox" (click)="multiSelect($event, element)"
                (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)" />
            </div>
          </td>
        </ng-container>

        <!-- #end ng-container -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- No data message -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell message" colspan="99">No Data to display</td>
        </tr>
      </table>
      <!-- #end: Table -->


    </div>
  </div>

  <div class="popup-paginator">
    <!-- Paginator -->
    <div *ngIf="data.title != 'Error Data' && data.type != 'BulkApproveRejectSendBackError'">
    <mat-paginator id="activePaginator" [pageSizeOptions]="pageSizeOptions" [pageSize]="activePageSize"
      [length]="activeTotalRecords" showFirstLastButtons aria-label="Select page" class="paginator"
      (page)="pageChanged($event)">
    </mat-paginator>
  </div>
    <!-- #end : Paginator -->
  </div>

</div>