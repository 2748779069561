import { PageEvent } from "@angular/material/paginator";
import { DefaultPageSize } from "app/shared/config/pagination.config";
import { OrderBySpecs } from "./orderBySpecs.model";
import { FilterConditions, IFilterConditions, IFilterRule, operator } from "./query.filter.interface";

export interface IAdditionalFilterObject {
  key: string;
  filterValues: string[];
}

export class AdditionalFilterObject implements IAdditionalFilterObject {
  key: string;
  filterValues: string[];
}

export interface IQuerySpecs {
  IncludeDeleted: boolean;
  PaginationSpecs: IPaginationSpecsDto;
  FilterConditions?: IFilterConditions | null;
  OrderBySpecs?: OrderBySpecs[];
  AdditionalFilters?: IAdditionalFilterObject[];
  DisplayColumns: string[];
}

export class QuerySpecs implements IQuerySpecs {
  public IncludeDeleted: boolean = false;
  public FilterConditions?: IFilterConditions = new FilterConditions();
  public PaginationSpecs: IPaginationSpecsDto = new PaginsationSpecsDto();
  public OrderBySpecs?: OrderBySpecs[] = [];
  public AdditionalFilters?: IAdditionalFilterObject[] = [];
  public DisplayColumns: string[] = [];

  /**
   *
   */
  constructor() {
    this.IncludeDeleted = false;
    this.FilterConditions = new FilterConditions();
    this.PaginationSpecs = new PaginsationSpecsDto();
    this.OrderBySpecs = [];
    this.AdditionalFilters = [];
    this.DisplayColumns = [];
  }

  public static BuildQuery(
    rules: IFilterRule[],
    paginationSpecs: PaginsationSpecsDto,
    orderBy: OrderBySpecs[],
    displayColumns: string[],
    additionalFilters?: IAdditionalFilterObject[]
  ): QuerySpecs {
    let query: QuerySpecs = new QuerySpecs();
    query.FilterConditions.rules = rules;
    query.PaginationSpecs = paginationSpecs;
    query.OrderBySpecs = orderBy;
    query.DisplayColumns = displayColumns;
    if (additionalFilters?.length) {
      query.AdditionalFilters = additionalFilters;
    }
    return query;
  }

  public SetDefaultPagination(): QuerySpecs {
    this.PaginationSpecs = {
      PaginationRequired: true,
      Limit: DefaultPageSize,
      Page: 1,
    };
    return this;
  }

  public UpdatePagination(event: PageEvent): QuerySpecs {
    if (this.PaginationSpecs.Limit == event.pageSize) {
      this.PaginationSpecs.Page = event.pageIndex + 1;
    } else {
      let visibleFrom = (this.PaginationSpecs.Page - 1) * this.PaginationSpecs.Limit + 1;
      this.PaginationSpecs.Page = Math.ceil(visibleFrom / event.pageSize);
      this.PaginationSpecs.Limit = event.pageSize;
    }

    return this;
  }

  public UpdateOrderBy(field: string, dir: "asc" | "desc" | "" = "asc"): QuerySpecs {
    this.OrderBySpecs = [];
    this.OrderBySpecs.push({
      field: field,
      direction: dir,
    });
    this.PaginationSpecs.Page = 1;
    return this;
  }

  public UpdateFilter(field: string, value: any, operator: operator = "contains"): QuerySpecs {
    let rule: IFilterRule = {
      field: field,
      operator: operator,
      value: value,
    };

    if (!this.FilterConditions) {
      this.FilterConditions = new FilterConditions();
      this.FilterConditions.rules = [];
    }

    if (this.FilterConditions.rules) {
      let i = this.FilterConditions.rules.findIndex((f) => f.field === rule.field);
      if (i >= 0) {
        this.FilterConditions.rules[i] = rule;
      } else {
        this.FilterConditions.rules.push(rule);
      }
    }

    //reset pagination
    this.PaginationSpecs.Page = 1;

    return this;
  }

  public UpdateFilter2(field: string, value1: any, value2: any, operator: operator = "contains"): QuerySpecs {
    let rule1: IFilterRule = {
      field: field,
      operator: "gte",
      value: value1,
    };
    let rule2: IFilterRule = {
      field: field,
      operator: "lte",
      value: value2,
    };
    if (!this.FilterConditions) {
      this.FilterConditions = new FilterConditions();
      this.FilterConditions.rules = [];
    }

    if (this.FilterConditions.rules) {
      let i = this.FilterConditions.rules.findIndex((f) => f.field === rule1.field && f.operator == "gte");
      let j = this.FilterConditions.rules.findIndex((f) => f.field === rule2.field && f.operator == "lte");

      if (i >= 0 && j >= 0) {
        this.FilterConditions.rules[i] = rule1;
        this.FilterConditions.rules[j] = rule2;
      } else {
        this.FilterConditions.rules.push(rule1);
        this.FilterConditions.rules.push(rule2);
      }

      //reset pagination
      this.PaginationSpecs.Page = 1;

      return this;
    }
  }

  // Update Advance Filter
  public UpdateAdvanceFilter(key: string, value: any): QuerySpecs {
    let additionalFilters: IAdditionalFilterObject = {
      key: key,
      filterValues: [value],
    };

    if (this.AdditionalFilters) {
      let i = this.AdditionalFilters.findIndex((f) => f.key === additionalFilters.key);

      if (i >= 0) {
        this.AdditionalFilters[i] = additionalFilters;
      } else {
        this.AdditionalFilters.push(additionalFilters);
      }
    }

    return this;
  }
}

export interface IPaginationSpecsDto {
  PaginationRequired: boolean;
  Page: number;
  Limit: number;
}

export class PaginsationSpecsDto implements IPaginationSpecsDto {
  public PaginationRequired: boolean = false;
  public Page: number = 1;
  public Limit: number = 0;
}

export class PaginationSpecs implements IPaginationSpecsDto {
  public PaginationRequired: boolean = true;
  public Page: number = 1;
  public Limit: number = 10;
}

export class MasterPaginationSpecs implements IPaginationSpecsDto {
  public PaginationRequired: boolean = true;
  public Page: number = 1;
  public Limit: number = 50;
}
