import { Observable } from "rxjs";
import { MatDialogRef, MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Injectable } from "@angular/core";

import { AppComfirmComponent } from "./app-confirm.component";

interface confirmData {
  title?: string;
  message?: string;
  okText?: string;
  cancelText?: string;
  focus?: "ok" | "cancel";
}

@Injectable()
export class AppConfirmService {
  constructor(private dialog: MatDialog) {}
  public confirm(data: confirmData = {}): Observable<boolean> {
    data.title = data.title || "Please Confirm";
    data.message = data.message || "Are you sure?";
    data.okText = data.okText || "Yes";
    data.cancelText = data.cancelText || "Cancel";
    data.focus = data.focus || "ok";

    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: "380px",
      disableClose: true,
      data: { title: data.title, message: data.message, okText: data.okText, cancelText: data.cancelText },
    });
    return dialogRef.afterClosed();
  }

  public confirmCancel(data: confirmData = {}): Observable<boolean> {
    const defaultdialog: confirmData = {
      title: "Your changes will be lost",
      message: "Are you sure you want to cancel?",
      okText: "Yes",
      cancelText: "No",
    };

    data.title = data.title || defaultdialog.title;
    data.message = data.message || defaultdialog.message;
    data.okText = data.okText || defaultdialog.okText;
    data.cancelText = data.cancelText || defaultdialog.cancelText;
    data.focus = data.focus || "ok";

    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: "380px",
      disableClose: true,
      data: { ...data },
    });
    return dialogRef.afterClosed();
  }
  public confirmDelete(data: confirmData = {}): Observable<boolean> {
    const defaultdialog: confirmData = {
      title: "Delete?",
      message: "Are you sure you want to delete this entry?",
      okText: "Yes",
      cancelText: "No",
    };

    data.title = defaultdialog.title;
    data.message = defaultdialog.message;
    data.okText = defaultdialog.okText;
    data.cancelText = defaultdialog.cancelText;
    data.focus = "ok";

    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: "380px",
      disableClose: true,
      data: { ...data },
    });
    return dialogRef.afterClosed();
  }

  public confirmApprove(data: confirmData = {}): Observable<boolean> {
    const defaultdialog: confirmData = {
      title: "Are you sure?",
      message: "Are you sure you want to Approve?",
      okText: "Yes",
      cancelText: "No",
    };

    data.title = defaultdialog.title;
    data.message = defaultdialog.message;
    data.okText = defaultdialog.okText;
    data.cancelText = defaultdialog.cancelText;
    data.focus = "ok";

    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: "380px",
      disableClose: true,
      data: { ...data },
    });
    return dialogRef.afterClosed();
  }
  public rejectApprove(data: confirmData = {}): Observable<boolean> {
    const defaultdialog: confirmData = {
      title: "Are you sure?",
      message: "Are you sure you want to Reject?",
      okText: "Yes",
      cancelText: "No",
    };

    data.title = defaultdialog.title;
    data.message = defaultdialog.message;
    data.okText = defaultdialog.okText;
    data.cancelText = defaultdialog.cancelText;
    data.focus = "ok";

    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: "380px",
      disableClose: true,
      data: { ...data },
    });
    return dialogRef.afterClosed();
  }

  public sendBackApprove(data: confirmData = {}): Observable<boolean> {
    const defaultdialog: confirmData = {
      title: "Are you sure?",
      message: "Are you sure you want to Send Back?",
      okText: "Yes",
      cancelText: "No",
    };

    data.title = defaultdialog.title;
    data.message = defaultdialog.message;
    data.okText = defaultdialog.okText;
    data.cancelText = defaultdialog.cancelText;
    data.focus = "ok";

    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: "380px",
      disableClose: true,
      data: { ...data },
    });
    return dialogRef.afterClosed();
  }
}
