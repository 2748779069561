<div
  id="page-root"
  fxLayout="column"
  [@animate]="{
    value: '*',
    params: { opacity: '0', y: '40px', delay: '250ms' }
  }"
>
  <div id="page-root" fxLayout="column">
    <div class="wrapper box" id="page-content">
      <div class="tablebox">
        <!-- Table -->
        <table
          mat-table
          [dataSource]="dataSource"
          class="lms-table"
          #productTable="matSort"
          matTableFilter
          matSort
          MatSortActive="name"
          matSortDirection="asc"
          (matSortChange)="sort()"
        >
          <!-- ng-container -->
          <ng-container [matColumnDef]="column.id" *ngFor="let column of columnsDef">
            <th mat-header-cell *matHeaderCellDef>
              <!-- Column Name With Sorting -->
              <div class="col-name" *ngIf="column?.controls && !column?.additional" fxLayout="row" mat-sort-header>
                {{ column.name }}
              </div>

              <!-- Column Name Without Sorting  -->
              <div *ngIf="!column?.controls || column?.additional">
                {{ column.name }}
              </div>

              <!-- Filter FormControl -->
              <div *ngIf="column?.type == 'text' || column?.type == 'array'">
                <input class="inlinesearch" [formControl]="column?.controls" />
              </div>

              <div *ngIf="column?.type == 'number'">
                <input type="number" class="inlinesearch" [formControl]="column?.controls" />
              </div>

              <!-- Filter FormControl -->
              <div *ngIf="column?.type == 'date'">
                <!-- <input [name]="column?.controls" type="date" class="inlinesearch" [formControl]="column?.controls" /> -->

                <!-- <div> -->
                <div fxLayout="row" class="date-filter">
                  <input
                    class="dummy-picker"
                    placeholder="DD/MM/YYYY"
                    [formControl]="column?.controls"
                    matInput
                    [matDatepicker]="picker"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <!-- </div> -->
              </div>

              <!-- Filter FormControl with DropDown -->
              <div *ngIf="column?.type == 'boolean' && column?.controls">
                <mat-select [formControl]="column?.controls" class="inlinesearch">
                  <mat-option value="">All</mat-option>
                  <mat-option value="true">Yes</mat-option>
                  <mat-option value="false">No</mat-option>
                </mat-select>
              </div>

              <!-- Approval Status -->
              <div *ngIf="column?.type == 'approval' && column?.controls">
                <mat-select [formControl]="column?.controls" class="inlinesearch">
                  <mat-option value="">All</mat-option>
                  <mat-option value="00">Pending For Submission</mat-option>
                  <mat-option value="01">In Progress</mat-option>
                  <mat-option value="02">SendBack</mat-option>
                  <mat-option value="03">Rejected</mat-option>
                  <mat-option value="99">System Approved</mat-option>
                  <mat-option value="100">Approved</mat-option>
                </mat-select>
              </div>
            </th>

            <td mat-cell *matCellDef="let element">
              <!-- Data with True Yes Pipe -->
              <p *ngIf="column.type == 'boolean'">
                {{ element[column.id] | trueyes }}
              </p>
              <p *ngIf="column.type == 'approval'">
                {{ element | approval: element:"report" }}
              </p>

              <div class="list-image" *ngIf="column.type == 'image'">
                <img *ngIf="element[column.id]" [src]="imageUrl + element[column.id]" />
                <img *ngIf="!element[column.id]" [src]="defImageUrl" />
              </div>

              <!-- Type : Text -->
              <!-- If value has length -->
              <p *ngIf="column.type == 'text' && element[column.id]" [matTooltip]="element[column.id]">
                {{ element[column.id] | excerpt: "50" }}
              </p>
              <p *ngIf="column.type == 'array' && element[column.id]">
                {{ element[column.id][0]?.roleName | excerpt: "50" }}
              </p>
              <!-- If value has length = 0 -->
              <p *ngIf="column.type == 'text' && !element[column.id]">
                {{ element[column.id] }}
              </p>
              <p *ngIf="column.type == 'array' && !element[column.id]">
                {{ element[column.id][0]?.roleName | excerpt: "50" }}
              </p>

              <p *ngIf="column.type == 'number'">{{ element[column.id] }}</p>

              <!-- Type : Date with date pipe -->
              <p *ngIf="column.type == 'date'">
                {{ element[column.id] | date: "dd/MM/yyyy" }}
              </p>

              <!-- Actions buttons -->

              <!-- Edit Button-->
              <!-- This edit button used for open dialog component -->
              <button
                *ngIf="column.id == 'action' && column?.isDialog && canEdit"
                mat-icon-button
                class="action"
                matTooltip="Edit"
                (click)="openDialog(element.id, 'edit')"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                *ngIf="column.id == 'action' && key == 'pinCode'"
                mat-icon-button
                class="action"
                matTooltip="Edit"
                [routerLink]="['edit', element.pincode]"
              >
                <mat-icon>edit</mat-icon>
              </button>

              <!-- Edit Button -->
              <!-- This edit button used for open Edit page using router link -->
              <button
                mat-icon-button
                *ngIf="column.id == 'action' && !column?.isDialog && key != 'pinCode'"
                matTooltip="Edit"
                class="action"
                [routerLink]="['edit', element.id]"
              >
                <mat-icon>edit</mat-icon>
              </button>

              <!-- View Button -->
              <button
                *ngIf="column.id == 'action' && !column?.isDialog && key != 'pinCode'"
                mat-icon-button
                class="action"
                matTooltip="view"
                [routerLink]="['view', element.id]"
              >
                <mat-icon>visibility</mat-icon>
              </button>

              <button
                *ngIf="column.id == 'action' && !column?.isDialog && key == 'pinCode'"
                mat-icon-button
                class="action"
                matTooltip="view"
                [routerLink]="['view', element.pincode]"
              >
                <mat-icon>visibility</mat-icon>
              </button>

              <!-- Delete Button -->
              <button
                *ngIf="column.id == 'action' && canDelete"
                mat-icon-button
                class="action"
                matTooltip="Delete"
                (click)="delete(element)"
              >
                <mat-icon>delete_outline</mat-icon>
              </button>

              <!-- Additional Buttons -->
              <!-- Role Permission -->
              <!-- This button used in 'Role ListPage' for move to role permisstion page  -->
              <button
                *ngIf="column.id == 'action' && column?.rolePermission"
                mat-icon-button
                class="action"
                matTooltip="Role Permission"
              >
                <a mat-icon-button [routerLink]="['role-permission', element.id]">
                  <mat-icon>settings</mat-icon>
                </a>
              </button>
            </td>
          </ng-container>
          <!-- #end ng-container -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; sticky: true"></tr>
          <!-- No data message -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell message" colspan="99">No Data to display</td>
          </tr>
        </table>
        <mat-paginator
          #paginator
          id="activePaginator"
          [pageSizeOptions]="[10, 50, 100]"
          [pageSize]="activePageSize"
          showFirstLastButtons
          aria-label="Select page"
          class="paginator"
        >
        </mat-paginator>
        <!-- #end: Table -->
      </div>

      <!-- Paginator -->

      <!-- #end : Paginator -->
    </div>
  </div>
</div>
