import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IUserProfileDto } from 'app/shared/models/auth/user.profile.interface';
import { IPageAction } from 'app/shared/models/shared/page-action.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'lms-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit,OnDestroy {

  bookmark = false;
  private destroy$: Subject<any> = new Subject();
  userProfile: IUserProfileDto;
  bookmarkId: number = 0;
  constructor() {
    this.destroy$ = new Subject();
  }

  @Input()
  title: string;

  @Input()
  description: string;

  @Input()
  subtitle: string;

  @Input()
  pageActions: IPageAction[] = [];

  @Output()
  actionEvent = new EventEmitter<string>();

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  actionClick($event: any) {
    this.actionEvent.emit($event.currentTarget.id);
  }

}
