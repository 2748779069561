<div class="app-admin-wrap">
  <!-- Main Container -->
  <mat-sidenav-container
    [dir]="layoutConf.dir"
    class="app-admin-container app-side-nav-container mat-drawer-transition sidebar-{{layoutConf?.sidebarColor}} topbar-{{layoutConf?.topbarColor}} footer-{{layoutConf?.footerColor}}"
    [ngClass]="adminContainerClasses"
  >
    <mat-sidenav-content>
      <!-- SIDEBAR -->
      <!-- REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
      <app-sidebar-side *ngIf="layoutConf.navigationPos === 'side'"></app-sidebar-side>

      <!-- App content -->
      <div
        class="main-content-wrap"
        id="main-content-wrap"
        [perfectScrollbar]=""
        [disabled]="layoutConf.topbarFixed || !layoutConf.perfectScrollbar"
      >
        <!-- Header for side navigation layout -->
        <app-header-side [notificPanel]="notificationPanel"> </app-header-side>
        <!-- REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
        <div
          class="rightside-content-hold"
          id="rightside-content-hold"
          [perfectScrollbar]="scrollConfig"
          [disabled]="!layoutConf.topbarFixed || !layoutConf.perfectScrollbar"
        >
          <!-- View Loader -->
          <div
            class="view-loader"
            *ngIf="isModuleLoading"
            style="position: fixed"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <div class="spinner">
              <div class="double-bounce1 mat-bg-accent"></div>
              <div class="double-bounce2 mat-bg-primary"></div>
            </div>
          </div>
          <!-- View outlet -->
          <div class="container-dynamic">
            <router-outlet></router-outlet>
          </div>
        </div>
        <span class="m-auto" *ngIf="layoutConf.footerFixed"></span>
      </div>
      <!-- View overlay for mobile navigation -->
      <div
        class="sidebar-backdrop"
        [ngClass]="{'visible': layoutConf.sidebarStyle !== 'closed' && layoutConf.isMobile}"
        (click)="closeSidebar()"
      ></div>
    </mat-sidenav-content>

    <!-- Notificaation bar -->
    <mat-sidenav #notificationPanel mode="over" class="" position="end">
      <div class="nofication-panel" fxLayout="column">
        <app-notifications [notificPanel]="notificationPanel"></app-notifications>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
