<div id="page-root" fxLayout="column">
    <div id="page-content">
      <form [formGroup]="userPasswordForm" fxLayout="column" (ngSubmit)="changepassword()">
        <div id="page-header" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
            <div id="page-title" fxLayout="column" fxFlex>
              <div fxLayout="row" fxFlex>
                <div class="title">
                  <h2>{{ title }}&nbsp;</h2>
                </div>
              </div>
            </div>
            <div id="page-actions" fxLayout="row" fxFlex fxLayoutAlign="end center" fxLayoutGap="6px">
              <button mat-raised-button type="submit"><i class="material-icons">save</i> Save</button>
            </div>
          </div>
        </div>
  
        <div class="lms-form">
          <div class="field-col">
            <!--City -->
            <div class="lms-field-group-wrapper">
              <div class="label-wrapper">
                <mat-label>Old Password<span class="req">*</span> </mat-label>
              </div>
              <div class="field-wrapper">
                <input type="password" formControlName="oldPassword" name="oldPassword" />
              </div>
            </div>
  
            <!-- Name in Hindi -->
            <div class="lms-field-group-wrapper">
              <div class="label-wrapper">
                <mat-label>Password<span class="req">*</span></mat-label>
              </div>
              <div class="field-wrapper">
                <input type="password" formControlName="newPassword" name="newPassword" />
              </div>
            </div>
  
            <!-- State -->
            <div class="lms-field-group-wrapper">
              <div class="label-wrapper">
                <mat-label>Confirm Password<span class="req">*</span></mat-label>
              </div>
              <div class="field-wrapper">
                <input type="password" formControlName="confirmpassword" name="confirmpassword" />
              </div>
            </div>

          </div>
          <div class="field-col"></div>
        </div>
      </form>
    </div>
  </div>
  <!-- </div> -->
  