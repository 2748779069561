import { Component, OnInit } from "@angular/core";
import { IUserDto, UserDto } from "app/shared/models/masters";
import { IPageAction } from "app/shared/models/shared/page-action.interface";
import { Location } from "@angular/common";
import { AuthService } from "app/shared/services/auth/auth.service";
import { IUserProfileDto } from "app/shared/models/auth/user.profile.interface";
import { environment } from "environments/environment";
import { API_ENDPOINTS } from "app/shared/config/api-endpoints.config";
import { HttpService } from "app/shared/services/http/http.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { IUserStoreMappingDto, UserStoreMappingDto } from "app/shared/models/masters/user/userStoreMappingDto.model";

const userAPI: string = API_ENDPOINTS.user.base;

@Component({
  selector: "lms-profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: ["./profile-page.component.scss"],
})
export class ProfilePageComponent implements OnInit {
  title: string;
  subTitle: string = "User Details";
  userImage: string;
  user: IUserDto;
  userProfile: IUserProfileDto;
  userDetails: UserDto = new UserDto();
  destroy$: Subject<any>;
  pageActions: IPageAction[];
  isAdmin = new FormControl();
  dataSource: MatTableDataSource<IUserStoreMappingDto>;
  displayedColumns: string[] = ["stores"];

  constructor(private location: Location, private authService: AuthService, private _dataService: HttpService) {
    this.dataSource = new MatTableDataSource<UserStoreMappingDto>();
    this.title = "User Profile";
    this.user = new UserDto();
    this.destroy$ = new Subject();
    this._configurePageActions();
    this.userImage = "../../assets/images/avatars/default-image.png";
  }

  ngOnInit(): void {
    let id: number = 0;
    this.authService.userProfile$.subscribe((res) => {
      this.userProfile = res;
      id = res.id;
    });

    if (id) {
      this._dataService
        .getData(id, userAPI)
        .pipe(takeUntil(this.destroy$))
        .subscribe((user) => {
          this.user = user.data;
          this.user.imageUploadPath = environment.domain + user.data.imageUploadPath;
          this.user.roles[0]?.roleName;
          this.isAdmin.patchValue(this.user.isAdmin);
          this.isAdmin.disable();
          this.dataSource.data = this.user.stores;
        });
    }
  }

  private _configurePageActions(): void {
    const back = () => {
      this.location.back();
    };

    this.pageActions = [
      {
        caption: "Back",
        name: "back",
        action: back,
        icon: "arrow_back",
      },
    ];
  }
}
