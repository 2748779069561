// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

export const environment = {
  production: true,
  KarzaURL: "https://testapi.karza.in",
  KarzaKey: "AWHXCUdN2CiAyOEY", // test

  // Live server
  domain: "https://api.gramhaat.in/",
  apiURL: "https://api.gramhaat.in/api",

  // // test server
  // domain: "https://app2.accusol.com:403/",
  // apiURL: 'https://app2.accusol.com:403/api'

  // development server
  // domain: "http://app2.accusol.com:405/",
  // apiURL: "http://app2.accusol.com:405/api",
};
