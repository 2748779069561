import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AlertsService } from "app/shared/services/alerts/alerts.service";
import { API_ENDPOINTS } from "app/shared/config/api-endpoints.config";
import { httpOptions } from "app/shared/config/httpOptions";
import { ILoginDto } from "app/shared/models/auth/login/loginDto.model";
import { ILoginResponseDto } from "app/shared/models/auth/login/loginResponse.model";
import { ResponseMessage } from "app/shared/models/shared/responseMessage.model";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, retry, tap } from "rxjs/operators";
import { IUserProfileDto } from "app/shared/models/auth/user.profile.interface";
import { IForgotPasswordDto } from "app/shared/models/auth/forgot/IForgotPasswordDto.model";

const loginApi: string = API_ENDPOINTS.Auth.login;
const UserProfileAPI: string = API_ENDPOINTS.Auth.profile;
const LOGOUT_RETURN_PATH: string = "sessions/signin";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  //#region private properties
  public userProfile: BehaviorSubject<IUserProfileDto>;
  public userProfile$: Observable<IUserProfileDto>;
  private _token: string;

  //#region Page construct
  // -----------------------------------------------------------------------------------------------
  // @ constructor
  // -----------------------------------------------------------------------------------------------

  /**
   * Constructor
   * @param _http: HttpClient
   * @param _alertService: AlertService
   * @param _router: Router
   */
  constructor(private _http: HttpClient, private _alertService: AlertsService, private _router: Router) {
    this._token = localStorage.getItem("token");
    this.userProfile = new BehaviorSubject(null);
    this.userProfile$ = this.userProfile.asObservable();
  }
  //#endregion Page construct

  // -----------------------------------------------------------------------------------------------------
  // @ Property accessors
  // -----------------------------------------------------------------------------------------------------

  public get isLoggedIn$(): Observable<boolean> {
    if (!this._token) {
      return of(false);
    } else {
      if (this.userProfile?.value === null) {
        return this._http.get<ResponseMessage<IUserProfileDto>>(UserProfileAPI, httpOptions).pipe(
          retry(2),
          map(
            (res: any) => {
              if (res.success) {
                this.userProfile.next(res.data);
                return true;
              } else {
                this.userProfile.next(null);
                return false;
              }
            },
            (err: any) => {
              return false;
            }
          )
        );
      } else {
        return of(true);
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign In
   * @param loginDto
   * @returns
   */
  public login(loginDto: ILoginDto): Observable<ResponseMessage<ILoginResponseDto>> {
    return this._http.post<ResponseMessage<ILoginResponseDto>>(loginApi, loginDto, httpOptions).pipe(
      tap((res: any) => {
        if (res.success && res.data?.token) {
          if (res.data.token) {
            localStorage.setItem("token", res.data.token);
            this._token = res.data.token;
          }
        } else {
          this.userProfile.next(null);
          this._token = "";
          localStorage.removeItem("token");
          this._alertService.raiseErrorAlert("Unable to authenticate user. Contact system administrator");
        }
      })
    );
  }

  /**
   * Logout
   */
  public logout(): void {
    localStorage.removeItem("storeName");
    localStorage.removeItem("storeId");
    localStorage.removeItem("token");
    this.userProfile.next(null);
    this._router.navigate([LOGOUT_RETURN_PATH]);
  }

  public isAuthorized(key: string): boolean {
    return this.userProfile.value && this.userProfile.value.authKeys && this.userProfile.value.authKeys.includes(key);
  }


}
