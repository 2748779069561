import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { API_ENDPOINTS } from "../config/api-endpoints.config";
import { AlertsService } from "../services/alerts/alerts.service";
import { ProgressBarService } from "../components/progress-bar/progress-bar.service";
import { IAppAlert } from "../models/shared/alert.model";
import { ApiError } from "../models/shared/error.model";
import { ErrorService } from "../services/shared/error.service";
import { AuthService } from "../services/auth/auth.service";

/*
 * Error interceptor will intercept all non-api errors and wrap the same into standard
 * error object and pass this on to the Error service
 * so as to make error info available across application
 */

//The ERROR_PATH determines the path to redirect

const ERROR_PATH: string = "/app/error/"; // Not required

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private error: ApiError;
  private errMsg: string;
  private errCode: number;
  private alert: IAppAlert;

  constructor(
    private errorService: ErrorService,
    private alertService: AlertsService,
    private pbService: ProgressBarService,
    private authservice: AuthService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(res => {

      }),
      retry(1), //Retries the request two more times in case of error
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof ErrorEvent) {
          //This is a client side error
          this.errMsg = err.error.message;
          this.errCode = 9999; // <-- this code is reserved for any client side error captured
        } else {
          this.pbService.hide();
          this.errCode = err.status;
          switch (err.status) {
            case 0:
              this.errMsg = `LMS-Suyash Server is not reachable. Please try after sometime`;
              this.errCode = 404;
              this.alert = {
                code: this.errCode,
                type: "error",
                level: "page",
                message: this.errMsg,
                canDismiss: true,
                autoClose: false,
              };
              if (req.url.endsWith(API_ENDPOINTS.Auth.login)) {
                throwError(new ApiError(this.errCode, this.errMsg));
              } else {
                this.alertService.raiseAlert(this.alert);
              }
              break;

            case 401:
              // this.errMsg = "Unauthorized resource access";
              this.router.navigate(["/sessions/signin"]);
              break;

            case 403:
              this.errMsg = "User is not authorized. Access denied";
              break;

            case 404:
              this.errMsg = `Requested end point ${req.url} is not available`;
              this.alert = {
                code: 404,
                type: "error",
                level: "page",
                message: this.errMsg,
                canDismiss: true,
                autoClose: false,
              };
              this.alertService.raiseAlert(this.alert);
              break;
            case 500:
              this.errMsg = "Internal server error";
              this.authservice.logout();
              break;
            case 503:
              this.errMsg = "Service not available at the moment. Please try again later";
            default:
              this.errMsg = "Unknown Server Error";
              break;
          }
        }
        this.error = {
          code: this.errCode,
          message: this.errMsg,
          alerts: null,
        };
        this.errorService.setError(this.error);
        return throwError(this.error); // Probably not required as error service will handle all the redirects
      })
    );
  }
}
