import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlertsService } from "app/shared/services/alerts/alerts.service";

@Component({
  selector: "lms-description-pop-up",
  templateUrl: "./description-pop-up.component.html",
  styleUrls: ["./description-pop-up.component.scss"],
})
export class DescriptionPopUpComponent implements OnInit {
  description: FormControl;
  constructor(public dialogRef: MatDialogRef<DescriptionPopUpComponent>, public _alertService: AlertsService) {
    this.description = new FormControl();
  }

  ngOnInit(): void {}

  public cancel(): void {
    this.dialogRef.close();
  }

  public addDescription(): void {
    if (this.description.value) {
      this.dialogRef.close(this.description?.value);
    } else {
      this._alertService.raiseErrorAlert("Please enter Description");
      return;
    }
  }
}
