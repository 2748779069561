// id : Use for binding and display columns
// name : For Display Table name
// controls : FormControl for input type
// filterField : Filter field for filter data. Field match with backend
// type : input type : Text | boolean | date
// isDialog :  if true it will open add | edit page in dialog. else open edit | add page in new Page.

import { FormControl } from "@angular/forms";

export const columns = {
  // -----------------------------------------------------------------------------------------------------
  // @ Masters column config
  // -----------------------------------------------------------------------------------------------------

  // State Master
  state: [
    {
      id: "name",
      name: "Name",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
    {
      id: "inActive",
      name: "Inactive",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],
  // State Master
  relation: [
    {
      id: "name",
      name: "Name",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
    {
      id: "inActive",
      name: "Inactive",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],

  //District Master
  district: [
    {
      id: "name",
      name: "District",
      controls: new FormControl(),
      filterField: "name",
      type: "text",
    },
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "State.Name",
      type: "text",
    },
    {
      id: "inActive",
      name: "Inactive",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],

  loanType: [
    {
      id: "type",
      name: "Type",
      controls: new FormControl(),
      filterField: "type",
      type: "text",
    },
    {
      id: "tenure",
      name: "Tenure",
      controls: new FormControl(),
      filterField: "tenure",
      type: "number",
    },
    {
      id: "inActive",
      name: "Inactive",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],

  kycDocuments: [
    {
      id: "name",
      name: "KYC Document Type",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
    {
      id: "isMandatory",
      name: "Is Mandatory?",
      controls: new FormControl(),
      filterField: "isMandatory",
      type: "boolean",
    },
    {
      id: "inActive",
      name: "Inactive?",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],

  paymentMode: [
    {
      id: "name",
      name: "Payment Mode",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
    {
      id: "inActive",
      name: "Inactive?",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],

  user: [
    { id: "imageUploadPath", name: "Image", type: "image" },
    {
      id: "name",
      name: "Name",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
    { id: "roles", name: "Role", controls: new FormControl(), filterField: "RoleName", additional: true, type: "array" },
    {
      id: "mobileNo",
      name: "Mobile No",
      controls: new FormControl(),
      filterField: "mobileNo",
      type: "text",
    },
    // {
    //   id: "storeName",
    //   name: "Store Name",
    //   controls: new FormControl(),
    //   filterField: "Store.Name",
    //   type: "text",
    // },
    {
      id: "isAdmin",
      name: "Is Admin",
      controls: new FormControl(),
      filterField: "isAdmin",
      type: "boolean",
    },
    {
      id: "inactive",
      name: "In Active",
      controls: new FormControl(),
      filterField: "inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],

  customer: [
    { id: "imageUploadPath", name: "", type: "image" },
    {
      id: "name",
      name: "Name",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
    // {
    //   id: "code",
    //   name: "Code",
    //   controls: new FormControl(),
    //   filterField: "code",
    //   type: "text",
    // },
    {
      id: "mobileNo",
      name: "Mobile No",
      controls: new FormControl(),
      filterField: "mobileNo",
      type: "text",
    },

    {
      id: "inActive",
      name: "Inactive",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],

  pinCode: [
    {
      id: "pincode",
      name: "Pincode",
      controls: new FormControl(),
      filterField: "pincode",
      type: "text",
    },
    {
      id: "cityName",
      name: "City",
      controls: new FormControl(),
      filterField: "City.Name",
      type: "text",
    },
    {
      id: "districtName",
      name: "District",
      controls: new FormControl(),
      filterField: "City.District.Name",
      type: "text",
    },
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "City.District.State.Name",
      type: "text",
    },
    {
      id: "inActive",
      name: "Inactive?",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },

    { id: "action", name: "Actions", isDialog: false },
  ],

  city: [
    {
      id: "name",
      name: "City",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
    {
      id: "districtName",
      name: "District",
      controls: new FormControl(),
      filterField: "district.Name",
      type: "text",
    },
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "District.State.Name",
      type: "text",
    },
    {
      id: "inActive",
      name: "Inactive",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],
  product: [
    {
      id: "name",
      name: "Name",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
  ],
  store: [
    {
      id: "name",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
    {
      id: "storeName",
      name: "Ziperp Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },
    {
      id: "plantName",
      name: "Plant Name",
      controls: new FormControl(),
      filterField: "plantName",
      type: "text",
    },

    {
      id: "tenantName",
      name: "Company",
      controls: new FormControl(),
      filterField: "tenantName",
      type: "text",
    },
    {
      id: "inActive",
      name: "Inactive",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],
  depositedocumenttypes: [
    {
      id: "name",
      name: "Deposit Document Type",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
    {
      id: "inActive",
      name: "Inactive ?",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],
  paymentreceipttypes: [
    {
      id: "name",
      name: "Payment Receipt Type",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
    {
      id: "inActive",
      name: "Inactive ?",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false },
  ],
  roles: [
    {
      id: "name",
      name: "Role",
      controls: new FormControl(),
      filterField: "Name",
      type: "text",
    },
    // { id: "nameHi", name: "Payment Receipt Type (Hindi)", controls: new FormControl(), filterField: "NameHi", type: "text" },
    {
      id: "inActive",
      name: "Inactive ?",
      controls: new FormControl(),
      filterField: "Inactive",
      type: "boolean",
    },
    { id: "action", name: "Actions", isDialog: false, rolePermission: true },
  ],
};
