import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AlertsService } from "../services/alerts/alerts.service";
import { AuthService } from "../services/auth/auth.service";

@Injectable()
export class UserRoleGuard implements CanActivate {
  constructor(private alertService: AlertsService, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let authKey = route.data.authKey;
    let title = route.data.title;
    if (authKey) {
      if (this.authService.isAuthorized(authKey)) {
        return true;
      } else {
        this.alertService.raiseErrorAlert(`Role assigned to you does not contain permission for ${title}`);
        return false;
      }
    } else {
      return true;
    }
  }
}
