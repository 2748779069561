<div class="sidebar-panel">
  <!-- App Logo -->
  <div class="branding">
    <a routerLink="/home"> <img src="assets/images/logo/gram haat.png" alt="" class="app-logo"></a>
  </div>

  <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
    <div class="sidebar-hold">


      <!-- Navigation -->
      <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle">
      </app-sidenav>
    </div>
  </div>
</div>