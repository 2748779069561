import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from '../shared-material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedDirectivesModule } from '../directives/shared-directives.module';

// REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from './header-side/header-side.component';
import { SidebarSideComponent } from './sidebar-side/sidebar-side.component';

// ALWAYS REQUIRED 
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { AppComfirmComponent } from '../services/app-confirm/app-confirm.component';
import { AppLoaderComponent } from '../services/app-loader/app-loader.component';
import { ButtonLoadingComponent } from './button-loading/button-loading.component';
import { ListPageComponent } from './list-page/list-page.component';
import { PageHeaderModule } from './page-header/page-header.module';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { DescriptionPopUpComponent } from './description-pop-up/description-pop-up.component';
import { GenPopupComponent } from './gen-popup/gen-popup.component';
import { ReportTableComponent } from './report-table/report-table.component';


const components = [
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  AppComfirmComponent,
  DescriptionPopUpComponent,
  ReportTableComponent,
  UnderConstructionComponent,
  GenPopupComponent,
  ListPageComponent,
  AppLoaderComponent,
  ButtonLoadingComponent,
]

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        PageHeaderModule,
        SharedPipesModule,
        SharedDirectivesModule,
        SharedMaterialModule
    ],
    declarations: components,
    exports: components
})
export class SharedComponentsModule {}