import { Injectable } from "@angular/core";
import { ApiError } from "app/shared/models/shared/error.model";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  private error: ApiError;
  constructor() {
    this.error = null;
  }

  public get hasError(): boolean {
    return this.error ? true : false;
  }

  public setError(error: ApiError): void {
    this.error = error;
  }

  public getError(): ApiError {
    return this.error;
  }
}
