import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { httpOptions } from "app/shared/config/httpOptions";
import { IFeaturesDto } from "app/shared/models/auth/role";
import { IRoleFeatureDto } from "app/shared/models/configurations/roleFeature.model";
import { IdrpListDto } from "app/shared/models/shared/drpList.model";
import { IPagedList } from "app/shared/models/shared/paged-list.model";
import { IQuerySpecs } from "app/shared/models/shared/querySpecs.model";
import { IResourceDto } from "app/shared/models/shared/resourceDto.model";
import { ResponseMessage } from "app/shared/models/shared/responseMessage.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  private _apiEndpoint: string;
  constructor(private _http: HttpClient) { }

  /**
   * POST : TO Get List
   * @param query
   * @param api
   * @returns
   */
  public getPageList(query: IQuerySpecs, api: string): Observable<ResponseMessage<IPagedList<any>>> {
    this._apiEndpoint = api;
    return this._http.post<ResponseMessage<IPagedList<any>>>(this._apiEndpoint, query, httpOptions);
  }

  /**
   * GET
   * @param id
   * @param api
   * @returns
   */
  public getData(id: number, api: string): Observable<ResponseMessage<any>> {
    this._apiEndpoint = api + "/" + id;
    return this._http.get<ResponseMessage<any>>(this._apiEndpoint, httpOptions);
  }

  /**
   * DELETE
   * @param id
   * @param api
   * @returns
   */
  public deleteData(id: string, api): Observable<ResponseMessage<IResourceDto>> {
    this._apiEndpoint = api + "/" + id;
    return this._http.delete<ResponseMessage<IResourceDto>>(this._apiEndpoint, httpOptions);
  }

  /**
   * PUT
   * @param data
   * @param api
   * @returns
   */
  public updateData(data: any, api: string): Observable<ResponseMessage<any>> {
    this._apiEndpoint = api;
    return this._http.put<ResponseMessage<any>>(this._apiEndpoint, data, httpOptions);
  }

  /**
   * POST : To add new record
   * @param data
   * @param api
   * @returns
   */
  public addData(data: any, api: string): Observable<ResponseMessage<IResourceDto>> {
    this._apiEndpoint = api;
    return this._http.post<ResponseMessage<IResourceDto>>(this._apiEndpoint, data, httpOptions);
  }

  public getFeatureActivityList(type: string[], api): Observable<ResponseMessage<IFeaturesDto[]>> {
    this._apiEndpoint = api;
    return this._http.post<ResponseMessage<IFeaturesDto[]>>(this._apiEndpoint, type, httpOptions);
  }

  /**
   * Get Dropdown List
   * @param api : API
   * @returns
   */
  public getDrpList(api): Observable<ResponseMessage<IdrpListDto[]>> {
    this._apiEndpoint = api;
    return this._http.get<ResponseMessage<IdrpListDto[]>>(this._apiEndpoint, httpOptions);
  }

  /**
   * Upload Document
   * @param api : API
   * @param File : Document Upload file
   * @returns
   */
  public uploadDoc(api: string, File: any): Observable<ResponseMessage<any>> {
    let header = new HttpHeaders();
    header.append("Content-Type", "multipart/form-data");
    this._apiEndpoint = api;
    return this._http.post<ResponseMessage<any>>(this._apiEndpoint, File, {
      headers: header,
    });
  }

  public upddateTolaStatus(api: string, data?): Observable<ResponseMessage<any>> {
    this._apiEndpoint = api;
    if (data) {
      return this._http.post<ResponseMessage<any>>(this._apiEndpoint, data, httpOptions);
    } else {
      return this._http.post<ResponseMessage<any>>(this._apiEndpoint, httpOptions);
    }
  }

  public upddateBulkTolaStatus(api: string, data?:any): Observable<ResponseMessage<any>>  {
    this._apiEndpoint = api;
    if (data) {
      let tempdata = {collectionIds:data}
      return this._http.post<ResponseMessage<any>>(this._apiEndpoint, tempdata, httpOptions);
    } else {
      return this._http.post<ResponseMessage<any>>(this._apiEndpoint, httpOptions);
    }
   
  }

  public upddateBulkTolaSendBack(api: string, data?:any): Observable<ResponseMessage<any>>  {
    this._apiEndpoint = api;
    if (data) {
      let tempdata = {sendBack:data}
      return this._http.post<ResponseMessage<any>>(this._apiEndpoint, tempdata, httpOptions);
    } else {
      return this._http.post<ResponseMessage<any>>(this._apiEndpoint, httpOptions);
    }
   
  }

  public upddateData(api: string, data?): Observable<ResponseMessage<any>> {
    this._apiEndpoint = api;
    if (data) {
      return this._http.put<ResponseMessage<any>>(this._apiEndpoint, data, httpOptions);
    } else {
      return this._http.put<ResponseMessage<any>>(this._apiEndpoint, httpOptions);
    }
  }

  public printData(id: number, api: string): Observable<Blob> {
    this._apiEndpoint = api + "/" + id;
    return this._http.get(this._apiEndpoint, { responseType: "blob" });
  }

  public incentivecollectionDownload(id: number, api: string): Observable<Blob> {
    this._apiEndpoint = api + "?TenantId=" + id;
    return this._http.get(this._apiEndpoint, { responseType: "blob" });
  }

  /**
   * GET
   * @param id
   * @param api
   * @returns
   */
  public getProductData(id: string, StoreId: string, api: string): Observable<ResponseMessage<any>> {
    this._apiEndpoint = api + "?ProductId=" + id + "&StoreId=" + StoreId;
    return this._http.post<ResponseMessage<any>>(this._apiEndpoint, httpOptions);
  }

  public postData(data: any, api: string): Observable<ResponseMessage<any>> {
    this._apiEndpoint = api;
    return this._http.post<ResponseMessage<any>>(this._apiEndpoint, data, httpOptions);
  }

  /**
   * GET
   * @param api
   * @returns
   */
  public getAllData(api: string): Observable<ResponseMessage<any>> {
    this._apiEndpoint = api;
    return this._http.get<ResponseMessage<any>>(this._apiEndpoint, httpOptions);
  }

  /**
   * GET
   * @param api
   * @returns
   */
  public getFiles(api: string): Observable<Blob> {
    this._apiEndpoint = api;
    return this._http.get(this._apiEndpoint, { responseType: "blob" });
  }

  public exportToExcel(query: IQuerySpecs, api: string): Observable<Blob> {
    this._apiEndpoint = api;
    return this._http.post(this._apiEndpoint, query, { responseType: "blob" });
  }
  public getExcelFile(api: string): Observable<Blob> {
    this._apiEndpoint = api;
    return this._http.get(this._apiEndpoint, { responseType: "blob" });
  }


  /**
  * GET
  * @param id
  * @param api
  * @returns
  */
  public postUserData(id: number, api: string): Observable<ResponseMessage<any>> {
    this._apiEndpoint = api + "/" + id;
    return this._http.post<ResponseMessage<any>>(this._apiEndpoint, httpOptions);
  }

  public uploadIncentiveCollection(api:string, effectiveDate: string, TenantId: string, form: any): Observable<ResponseMessage<any>> {
    let header = new HttpHeaders();
    header.append("Content-Type", "multipart/form-data");
    this._apiEndpoint = api + "/" + "?effectiveDate=" + effectiveDate + "&TenantId=" + TenantId;
    return this._http.post<ResponseMessage<any>>(this._apiEndpoint, form, {
      headers: header,
    });
  }

}


