<div id="page-root" fxLayout="column">
  <div id="page-content">
    <form fxLayout="column">
      <div id="page-header" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <div id="page-title" fxLayout="column" fxFlex>
            <div fxLayout="row" fxFlex>
              <div class="title">
                <h2>Description</h2>
              </div>
            </div>
          </div>
          <div id="page-actions" fxLayout="row" fxFlex fxLayoutAlign="end center" fxLayoutGap="6px">
            <button mat-raised-button type="button" (click)="cancel()">Cancel</button>
            <button mat-raised-button type="button" (click)="addDescription()">Save</button>
          </div>
        </div>
      </div>

      <div class="lms-form">
        <div class="field-col">
          <!--Description -->
          <div class="lms-field-group-wrapper">
            <div class="label-wrapper">
              <label>Description </label>
            </div>
            <div class="field-wrapper">
              <textarea type="text" class="description-box" [formControl]="description" name="name"></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
