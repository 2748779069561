<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button mat-icon-button id="sidenavToggle" (click)="toggleCollapse()">
    <mat-icon>menu</mat-icon>
  </button>

  <span fxFlex></span>

  <!-- Account -->

  <!-- Notification toggle button -->
  <!-- <button
    mat-icon-button
    matTooltip="Notifications"
    (click)="toggleNotific()"
    [style.overflow]="'visible'"
    class="topbar-button-right"
  >
    <mat-icon>notifications</mat-icon>
    <span  class="notification-number mat-bg-warn">0</span>
  </button> -->

  <!-- Settings button -->
  <button mat-icon-button routerLink="/settings" matTooltip="Settings">
    <mat-icon>settings</mat-icon>
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="['profile']">
      <mat-icon>account_box</mat-icon>
      <span>Profile</span>
    </button>

    <button mat-menu-item [routerLink]="['changepassword']">
      <mat-icon>key</mat-icon>
      <span>Change Password</span>
    </button>

    <!-- Logout button -->
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sign out</span>
    </button>
  </mat-menu>

  <button
    matRipple
    matTooltip="Account"
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button account-button"
  >
    <!-- <div class="p1" color>{{initials}}</div> -->
    <div class="p1" color><img style="width: inherit; height: inherit" src="{{initials}}" /></div>
    <div class="p2">
      <div class="name">{{userProfile?.name}}</div>
      <div class="email">{{userProfile?.mobileNo}}</div>
    </div>
    <div class="p3">
      <mat-icon style="color: white"> expand_more </mat-icon>
    </div>
  </button>
</mat-toolbar>
