import { MaxValidator } from "@angular/forms";
import { IUserRolesDto } from "../../auth/userRole.model";
import { IUserPincode } from "./user-pincode";
import { IUserStoreMappingDto } from "./userStoreMappingDto.model";

export interface IUserDto {
  id: number;
  name: string;
  nameHi: string;
  mobileNo: string;
  password: string;
  emailId: string;
  address: string;
  pincode: string;
  isAdmin: boolean;
  isNewImageUpload: boolean;
  imageUploadName: string;
  imageUploadPath: string;
  inactive: boolean;
  inActiveYN: string;
  reportingManagerId: string;
  reportingManagerName: string;
  cityId: string;
  cityName: string;
  cityNameHi: string;
  districtId: number;
  districtName: string;
  districtNameHi: string;
  stateId: number;
  stateName: string;
  stateNameHi: string;
  erpUserName?: string;
  erpUserId?: string;
  roles: IUserRolesDto[];
  pinCodes: IUserPincode[];
  stores?: IUserStoreMappingDto[];
}

export class UserDto implements IUserDto {
  id: number;
  name: string;
  nameHi: string;
  mobileNo: string;
  password: string;
  emailId: string;
  address: string;
  pincode: string;
  isAdmin: boolean;
  isNewImageUpload: boolean;
  imageUploadName: string;
  imageUploadPath: string;
  reportingManagerName: string;
  inactive: boolean;
  inActiveYN: string;
  reportingManagerId: string;
  cityId: string;
  cityName: string;
  cityNameHi: string;
  districtId: number;
  districtName: string;
  districtNameHi: string;
  stateId: number;
  stateName: string;
  stateNameHi: string;
  roles: IUserRolesDto[];
  pinCodes: IUserPincode[];
  stores?: IUserStoreMappingDto[] = [];
}
