import { SelectionModel } from "@angular/cdk/collections";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { API_ENDPOINTS } from "app/shared/config/api-endpoints.config";
import { DefaultPageSize, PageSizeOptions } from "app/shared/config/pagination.config";
import { OrderBySpecs } from "app/shared/models/shared/orderBySpecs.model";
import { IPageAction } from "app/shared/models/shared/page-action.interface";
import { IFilterConditions, IFilterRule } from "app/shared/models/shared/query.filter.interface";
import {
  IAdditionalFilterObject,
  IPaginationSpecsDto,
  PaginsationSpecsDto,
  QuerySpecs,
} from "app/shared/models/shared/querySpecs.model";
import { AuthService } from "app/shared/services/auth/auth.service";
import { HttpService } from "app/shared/services/http/http.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { popUpColumns } from "./popupList.config";

let DefaultFilter: IFilterRule[] = [];

let additionalFilters: IAdditionalFilterObject[];

const DefaultPagination: PaginsationSpecsDto = {
  PaginationRequired: true,
  Limit: 10,
  Page: 1,
};

const DefaultSortOrder: OrderBySpecs[] = [{ field: "CreatedDate", direction: "desc" }];
const DefaultDisplayColumn: string[] = [];

@Component({
  selector: "lms-pincode-popup",
  templateUrl: "./gen-popup.component.html",
  styleUrls: ["./gen-popup.component.scss"],
})
export class GenPopupComponent implements OnInit {
  // String / string[]
  title: string;
  displayedColumns: string[];
  apiEndPointList: string;

  // Number / Number[]
  currentPage: number;
  pageSize: number;
  activeTotalRecords: number;
  activePageSize: number;
  pageSizeOptions: number[] = PageSizeOptions;

  // Interface / class
  pageActions: IPageAction[] = [];
  pageSpecs: IPaginationSpecsDto;
  Filters: IFilterConditions;
  selectedData: any;
  multiSelectedData: any[] = [];

  // DataSource
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  // BehaviorSubject / Observables / Subject
  public noDataMsg: BehaviorSubject<string>;
  public noDataMsg$: Observable<string>;
  private _querySpecs: BehaviorSubject<QuerySpecs>;
  private _querySpecs$: Observable<QuerySpecs>;
  destroy$: Subject<any> = new Subject();

  public get activeCurrentPage(): number {
    return this._querySpecs.value.PaginationSpecs.Page - 1;
  }

  // ViewChild
  @ViewChild("MatSort") MatSort: MatSort;
  @ViewChild("activePaginator") paginatorActive: MatPaginator;

  //#endregion Public variables

  //#region constructor
  // ---------------------------------------------------------------------------------------------------------------
  // @ Constructor
  // ---------------------------------------------------------------------------------------------------------------

  // FormControls
  filters: FormControl[] = [];
  columnsDef: any;
  selection = new SelectionModel<any>(true, []);

  constructor(
    private _dataService: HttpService,
    private _router: Router,
    private _authService: AuthService,
    public dialogRef: MatDialogRef<GenPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      title: string;
      filterData: IFilterRule[];
      addFilterData: IAdditionalFilterObject[];
      isMultiple: boolean;
      data?: any;
    }
  ) {
  
    
    this.title = this.data.title;
    //configure no-data message
    this.noDataMsg = new BehaviorSubject("Loading...");
    this.noDataMsg$ = this.noDataMsg.asObservable();
    this.columnsDef = popUpColumns[this.data.type];
    this.displayedColumns = this.columnsDef.map((item) => item.id);

    if (this.data.type == "executive" || this.data.type == "manager") {
      this.apiEndPointList = API_ENDPOINTS.user.list;
    } else if (this.data.type == "UserPinCode") {
      this.apiEndPointList = API_ENDPOINTS.pinCode.list;
    } else if (this.data.type == "collection") {
      this.apiEndPointList = API_ENDPOINTS.collection.collectionCustomer;
    } else if (this.data.type == 'UserError'){
      this.dataSource.data = this.data.data
      
    }else if (this.data.type == 'StoreError'){
      this.dataSource.data = this.data.data
      
    }else if (this.data.type == 'ASEError'){
      this.dataSource.data = this.data.data
      
    }else if (this.data.type == 'BulkApproveRejectSendBackError'){
      this.dataSource.data = this.data.data
      
    } else {
      this.apiEndPointList = API_ENDPOINTS[this.data.type].list;
    }
    this.activePageSize = DefaultPageSize;

    this.activeTotalRecords = 0;
    if (this.data.type == "tola") {
      DefaultFilter.push({
        field: "TolaWorkflow.ApprovalStatus",
        operator: "eq",
        value: "00",
      });
    }

    if (this.data.type == "inquiries") {
      DefaultFilter.push(
        {
          field: "status",
          operator: "eq",
          value: "00",
        },
        {
          field: "inqType",
          operator: "eq",
          value: "ES",
        }
      );
    }
    if (this.data.type == "collection") {
      DefaultFilter = [
        {
          field: "IsForeClose",
          operator: "eq",
          value: "false",
        },
      ];
    }
    if (this.data.type != "collection" && this.data.type != "tola" && this.data.type != "inquiries") {
      DefaultFilter.push({
        field: "inActive",
        operator: "eq",
        value: false,
      });
    }
    if (this.data?.filterData?.length || this.data?.addFilterData?.length) {
      this._querySpecs = new BehaviorSubject(
        QuerySpecs.BuildQuery(
          this.data?.filterData?.length ? this?.data?.filterData : DefaultFilter,
          DefaultPagination,
          DefaultSortOrder,
          DefaultDisplayColumn,
          this.data.addFilterData?.length ? this.data?.addFilterData : []
        )
      );
    } else {
      this._querySpecs = new BehaviorSubject(
        QuerySpecs.BuildQuery(DefaultFilter, DefaultPagination, DefaultSortOrder, DefaultDisplayColumn)
      );
    }
    this._querySpecs$ = this._querySpecs.asObservable();
    this._configurePageActions();
  }

  ngOnInit(): void {
    this._observeQueryChanges();
    //subscribe to filter changes
    this._onSearchChanges();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.MatSort;
    this.dataSource.paginator = this.paginatorActive;
  }

  //ondestroy
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();

    this._querySpecs.next(null);
    this._querySpecs.complete();
    this._resetFilters();
    DefaultPagination.Limit = 10;
    DefaultPagination.Page = 1;
    DefaultPagination.PaginationRequired = true;
    DefaultFilter = [];
  }

  //#endregion Lifecycle hooks

  //#region Public Methods
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  // Page Change Method
  pageChanged(event: PageEvent) {
    this._querySpecs.next(this._querySpecs.value.UpdatePagination(event));
  }

  valueChange(index) {
    this.selectedData = this.dataSource.data[index];
    this.selectedData.push = this.dataSource.data[index];
  }

  submitProducts() {
    if (this.selectedData) {
      this.dialogRef.close(this.selectedData);
    }
    if (this.multiSelectedData?.length) {
      this.dialogRef.close(this.multiSelectedData);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  // Sorting
  sortData(sort: Sort) {
    if (!sort.active) {
      return;
    }
    let sortField: string;
    let query = this._querySpecs.value;

    if ((sort.direction as string) == "") {
      query.OrderBySpecs = DefaultSortOrder;
    } else {
      this.columnsDef.forEach((el) => {
        switch (sort.active) {
          case el.id:
            sortField = el?.filterField;
            break;
          default:
            break;
        }
      });
      query.UpdateOrderBy(sortField, sort.direction);
    }

    this._querySpecs.next(query);
  }

  multiSelect($event, selectedData: any) {
    if ($event.target.checked) {
      this.multiSelectedData.push(selectedData);
    } else {
      this.multiSelectedData.splice(this.multiSelectedData.indexOf(selectedData), 1);
    }
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Observe Query Changes
   * on change Query call List API
   */
  private _observeQueryChanges(): void {
    this._querySpecs$.pipe(takeUntil(this.destroy$)).subscribe((specs) => {
      if (specs) {
        this._getData(specs);
      }
    });
  }

  /**
   * _getData
   * Call list API Using Service
   * @param querySpecs : QuerySpecs
   */
  private _getData(querySpecs: QuerySpecs): void {
    if (this.data.type == "inquiries" || this.data.type == "tola" || this.data.type == "collection" ) {
      if (!this.apiEndPointList.includes("/true")) {
        this.apiEndPointList = this.apiEndPointList + "/true";
      }
    }

    if(this._authService.userProfile.value.isAdmin == false)
    {
      if (this.data.type == "user" || this.data.type == "roles" || this.data.type =="multiusers" ) {
        if (!this.apiEndPointList.includes("/true")) {
          this.apiEndPointList = this.apiEndPointList + "/true";
        }
      } 
    }

    this._dataService
      .getPageList(querySpecs, this.apiEndPointList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.success) {
          this.dataSource.data = res.data.items;
          this.activeTotalRecords = res.data.totalCount;
          this.activePageSize = res.data.pageSize;
          if (res.data.items.length == 0) {
            this.noDataMsg.next("No records found");
          }
        } else {
          this.noDataMsg.next("Unable to fetch data from server");
        }
      });
  }

  /**
   * OnChange FormControl Filter data
   */
  private _onSearchChanges(): void {
    /* search value observers */
    this.columnsDef.forEach((el) => {
      if (el?.controls && el?.type == "text") {
        // Filter for text value
        el.controls.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
          .subscribe((val) => {
            this._querySpecs.next(this._querySpecs.value.UpdateFilter(el.filterField, val));
          });
      } else if (el?.controls && el?.type == "boolean") {
        // Filter for boolean value
        el.controls.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
          .subscribe((val) => {
            this._querySpecs.next(this._querySpecs.value.UpdateFilter(el.filterField, val, "eq"));
          });
      }
    });
  }

  /**
   * configure Page Actions
   */
  private _configurePageActions(): void {
    // back
    const back = () => {
      this.dialogRef.close();
    };

    // Refresh
    const save = () => {
      if (this.selectedData) {
        this.dialogRef.close(this.selectedData);
      }
      if (this.multiSelectedData.length) {
        this.dialogRef.close(this.multiSelectedData);
      }
    };

    const CreateNewTola = () => {
      this.closeDialog();
      this._router.navigate(["transactions/tola/new"]);
    };
    if(this.data.type == "UserError" || this.data.type == "StoreError" || this.data.type == "ASEError" || this.data.type == "BulkApproveRejectSendBackError"){
      this.pageActions = [
        {
          caption: "Back",
          name: "back",
          action: back,
          icon: "arrow_back",
        },
      ]
    }
    else{
    this.pageActions = [
      {
        caption: "Back",
        name: "back",
        action: back,
        icon: "arrow_back",
      },
      {
        caption: "Save",
        name: "save",
        action: save,
        icon: "save",
      },
    ];
  }

    if (this.data.type == "tola") {
      this.pageActions.unshift({
        caption: "Create New Tola",
        name: "CreateNewTola",
        action: CreateNewTola,
        icon: "add",
      });
    }
  }

  /**
   * Reset FormControl
   */
  private _resetFilters(): void {
    // Filter Reset
    this.columnsDef.forEach((el) => {
      // Reset FormControl value
      if (el.controls?.value) {
        el.controls.reset();
      }
    });
  }
}
