import { FormControl } from "@angular/forms";

export const popUpColumns = {
  pinCode: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "pincode",
      name: "Pin Code",
      controls: new FormControl(),
      filterField: "pincode",
      type: "text",
    },
    {
      id: "cityName",
      name: "City Name",
      controls: new FormControl(),
      filterField: "City.Name",
      type: "text",
    },
    {
      id: "districtName",
      name: "District Name",
      controls: new FormControl(),
      filterField: "City.District.Name",
      type: "text",
    },
    {
      id: "stateName",
      name: "State Name",
      controls: new FormControl(),
      filterField: "City.District.State.Name",
      type: "text",
    },
  ],

  roles: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "Role",
      controls: new FormControl(),
      filterField: "name",
      type: "text",
    },
  ],

  user: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "User",
      controls: new FormControl(),
      filterField: "name",
      type: "text",
    },
  ],
  multiusers: [
    { id: "select", name: "Select", type: "checkbox" },
    {
      id: "name",
      name: "User",
      controls: new FormControl(),
      filterField: "name",
      type: "text",
    },
  ],
  manager: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "Manager",
      controls: new FormControl(),
      filterField: "name",
      type: "text",
    },
  ],

  state: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "State",
      controls: new FormControl(),
      filterField: "name",
      type: "text",
    },
  ],

  executive: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "Executive",
      controls: new FormControl(),
      filterField: "name",
      type: "text",
    },
    {
      id: "reportingManagerName",
      name: "Reporting Manager Name",
      controls: new FormControl(),
      filterField: "ReportingUser.Name",
      type: "text",
    },
    {
      id: "roles",
      name: "Role",
      // controls: new FormControl(),
      filterField: "RoleName",
      type: "text",
      addArrayfield:"roleName"
    },
  ],

  store: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "Store",
      controls: new FormControl(),
      filterField: "name",
      type: "text",
    },
    {
      id: "plantName",
      name: "Plant Name",
      controls: new FormControl(),
      filterField: "plantName",
      type: "text",
    },
    {
      id: "tenantName",
      name: "Tenant Name",
      controls: new FormControl(),
      filterField: "tenantName",
      type: "text",
    },
  ],

  customer: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "Customer Name",
      controls: new FormControl(),
      filterField: "name",
      type: "text",
    },
    {
      id: "mobileNo",
      name: "Phone Number",
      controls: new FormControl(),
      filterField: "phoneNo",
      type: "text",
    },
    {
      id: "emailId",
      name: "Email",
      controls: new FormControl(),
      filterField: "email",
      type: "text",
    },
    {
      id: "cityName",
      name: "City/Village",
      controls: new FormControl(),
      filterField: "city",
      type: "text",
    },
    {
      id: "pincode",
      name: "Pin Code",
      controls: new FormControl(),
      filterField: "pincode",
      type: "text",
    },
  ],

  tola: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "Tola Name",
      controls: new FormControl(),
      filterField: "name",
      type: "text",
    },
    {
      id: "tolaNo",
      name: "Tola Code",
      controls: new FormControl(),
      filterField: "tolaNo",
      type: "text",
    },
    {
      id: "cityName",
      name: "City/Village",
      controls: new FormControl(),
      filterField: "city",
      type: "text",
    },
    {
      id: "pincode",
      name: "Pin Code",
      controls: new FormControl(),
      filterField: "pincode",
      type: "text",
    },
    {
      id: "status",
      name: "Status",
      controls: new FormControl(),
      filterField: "pincode",
      type: "approvalStatus",
    },
  ],

  UserPinCode: [
    { id: "select", name: "Select", type: "checkbox" },
    {
      id: "pincode",
      name: "Pin Code",
      controls: new FormControl(),
      filterField: "pincode",
      type: "text",
    },
    {
      id: "cityName",
      name: "City Name",
      controls: new FormControl(),
      filterField: "City.Name",
      type: "text",
    },
    {
      id: "districtName",
      name: "District Name",
      controls: new FormControl(),
      filterField: "City.District.Name",
      type: "text",
    },
    {
      id: "stateName",
      name: "State Name",
      controls: new FormControl(),
      filterField: "City.District.State.Name",
      type: "text",
    },
  ],
  loanType: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "type",
      name: "Type",
      controls: new FormControl(),
      filterField: "type",
      type: "text",
    },
    {
      id: "tenure",
      name: "Tenure",
      controls: new FormControl(),
      filterField: "tenure",
      type: "number",
    },
  ],
  paymentMode: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "Payment Mode",
      controls: new FormControl(),
      filterField: "text",
      type: "text",
    },
  ],
  relation: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "Payment Mode",
      controls: new FormControl(),
      filterField: "text",
      type: "text",
    },
  ],

  paymentreceipttypes: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "Payment Receipt",
      controls: new FormControl(),
      filterField: "text",
      type: "text",
    },
  ],
  inquiries: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "name",
      name: "Customer Name",
      controls: new FormControl(),
      filterField: "text",
      type: "text",
    },
    {
      id: "inqNo",
      name: "Inquiry No.",
      controls: new FormControl(),
      filterField: "text",
      type: "text",
    },
    {
      id: "inqDate",
      name: "Inquiry Date",
      controls: new FormControl(),
      filterField: "inqDate",
      type: "date",
    },
    {
      id: "totalAmount",
      name: "Amount",
      controls: new FormControl(),
      filterField: "totalAmount",
      type: "number",
    },
  ],
  collection: [
    { id: "select", name: "Select", type: "radio" },
    {
      id: "customerName",
      name: "Customer Name",
      controls: new FormControl(),
      filterField: "customerName",
      type: "text",
    },
    {
      id: "loanNo",
      name: "Customer Code",
      controls: new FormControl(),
      filterField: "loanNo",
      type: "text",
    },
    {
      id: "tolaName",
      name: "Tola Name",
      controls: new FormControl(),
      filterField: "Tola.Name",
      type: "text",
    },
    {
      id: "tolaNo",
      name: "Tola No",
      controls: new FormControl(),
      filterField: "Tola.TolaNo",
      type: "text",
    },
  ],
  UserError: [
    {
      id: "userName",
      name: "User Name",
      type: "text",
    },
    {
      id: "importPincodes",
      name: "Pincodes",
      type: "text",
    },
    
  ],
  StoreError: [
    {
      id: "storeName",
      name: "Store Name",
      type: "text",
    },
    {
      id: "importPincodes",
      name: "Pincodes",
      type: "text",
    },
    
  ],
  ASEError: [
    {
      id: "tolaNo",
      name: "Tola No",
      type: "text",
    },
    {
      id: "aseUserName",
      name: "ASE User Name",
      type: "text",
    },
    
  ],
  BulkApproveRejectSendBackError: [
    {
      id: "loanNo",
      name: "Customer Code",
      type: "text",
    },
    {
      id: "tolaNo",
      name: "Tola Code",
      type: "text",
    },
    {
      id: "collectedAmount",
      name: "Collected Amount",
      type: "text",
    },
    {
      id: "importStatusDescription",
      name: "Reason",
      type: "text",
    },
  ],
};
