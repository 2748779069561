import { FormControl } from "@angular/forms";

export const reportcolumn = {
  // Collection Report
  collectionReport: [
    {
      id: "stateName",
      name: "State Name",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },
    {
      id: "customerName",
      name: "Customer Name",
      controls: new FormControl(),
      filterField: "customerName",
      type: "text",
    },
    {
      id: "tolaName",
      name: "Tola Name",
      controls: new FormControl(),
      filterField: "tolaName",
      type: "text",
    },
    {
      id: "customerMobileNumber",
      name: "Customer Mobile No",
      controls: new FormControl(),
      filterField: "customerMobileNumber",
      type: "number",
    },
    {
      id: "customerAddress",
      name: "Customer Address",
      controls: new FormControl(),
      filterField: "customerAddress",
      type: "text",
    },
    {
      id: "cityName",
      name: "City",
      controls: new FormControl(),
      filterField: "cityName",
      type: "text",
    },
    {
      id: "pincode",
      name: "Pincode",
      controls: new FormControl(),
      filterField: "pincode",
      type: "number",
    },
    {
      id: "aseName",
      name: "ASE Name",
      controls: new FormControl(),
      filterField: "aseName",
      type: "text",
    },
    {
      id: "smName",
      name: "SM Name",
      controls: new FormControl(),
      filterField: "smName",
      type: "text",
    },
    {
      id: "amName",
      name: "AM Name",
      controls: new FormControl(),
      filterField: "amName",
      type: "text",
    },
    {
      id: "collectionDay",
      name: "Collection Day",
      controls: new FormControl(),
      filterField: "collectionDay",
      type: "number",
    },
    {
      id: "expectedCollectionAmount",
      name: "Expected Collection Amount",
      controls: new FormControl(),
      filterField: "expectedCollectionAmount",
      type: "number",
    },
    {
      id: "emiAmount",
      name: "EMI Amount",
      controls: new FormControl(),
      filterField: "emiAmount",
      type: "number",
    },
    {
      id: "collectionAmount",
      name: "Received Amount",
      controls: new FormControl(),
      filterField: "collectionAmount",
      type: "number",
    },
    {
      id: "pendingCollectionAmount",
      name: "Pending Collection Amount",
      controls: new FormControl(),
      filterField: "pendingCollectionAmount",
      type: "number",
    },
    {
      id: "receivedDate",
      //name: "Product Disbursement Date",
      name: "Received Date",
      controls: new FormControl(),
      filterField: "receivedDate",
      type: "date",
    },
    // {
    //   id: "lastEMIDate",
    //   name: "Last EMI Date",
    //   controls: new FormControl(),
    //   filterField: "lastEMIDate",
    //   type: "date",
    // },

    {
      id: "collectionStatusName",
      name: "Collection Status(Received/Pending)",
      controls: new FormControl(),
      filterField: "collectionStatusName",
      type: "text",
    },
    {
      id: "modeOfPayment",
      name: "Mode of Payment",
      controls: new FormControl(),
      filterField: "modeOfPayment",
      type: "text",
    },
    {
      id: "transactionID",
      name: "Transaction ID",
      controls: new FormControl(),
      filterField: "transactionID",
      type: "text",
    },
    {
      id: "productDisbursementDate",
      name: "Disbursement Date",
      controls: new FormControl(),
      filterField: "productDisbursementDate",
      type: "date",
    },
    {
      id: "lastInstallmentDate",
      name: "Last Installment Date",
      controls: new FormControl(),
      filterField: "lastInstallmentDate",
      type: "date",
    },
    {
      id: "tenure",
      name: "Tenure",
      controls: new FormControl(),
      filterField: "tenure",
      type: "number",
    },
    {
      id: "productName",
      name: "Product Name",
      controls: new FormControl(),
      filterField: "productName",
      type: "text",
    },
    {
      id: "productAmount",
      name: "Product Amount",
      controls: new FormControl(),
      filterField: "productAmount",
      type: "number",
    },
  ],

  // Customer Inquiries Report
  customerInquiry: [
    {
      id: "date",
      name: "Date",
      controls: new FormControl(),
      filterField: "Inquiry.InqDate",
      type: "date",
    },
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },
    {
      id: "vleName",
      name: "Inquiry Created By",
      controls: new FormControl(),
      filterField: "vleName",
      type: "text",
    },
    {
      id: "createdThrough",
      name: "Created Through",
      controls: new FormControl(),
      filterField: "createdThrough",
      type: "text",
    },
    {
      id: "designation",
      name: "Created By Role",
      controls: new FormControl(),
      filterField: "designation",
      type: "text",
    },
    {
      id: "customerName",
      name: "Customer Name",
      controls: new FormControl(),
      filterField: "customerName",
      type: "text",
    },

    {
      id: "customerAddress",
      name: "Customer Address",
      controls: new FormControl(),
      filterField: "customerAddress",
      type: "text",
    },
    {
      id: "amount",
      name: "Amount",
      controls: new FormControl(),
      filterField: "amount",
      type: "number",
    },
    {
      id: "mobileNumber",
      name: "Mobile Number",
      controls: new FormControl(),
      filterField: "mobileNumber",
      type: "number",
    },

    {
      id: "pincode",
      name: "Pincode",
      controls: new FormControl(),
      filterField: "pincode",
      type: "number",
    },
    {
      id: "cityName",
      name: "City",
      controls: new FormControl(),
      filterField: "cityName",
      type: "text",
    },
    {
      id: "districtName",
      name: "District",
      controls: new FormControl(),
      filterField: "districtName",
      type: "text",
    },
    {
      id: "productName",
      name: "Products",
      controls: new FormControl(),
      filterField: "productName",
      type: "text",
    },
    {
      id: "status",
      name: "Inquiry Status",
      controls: new FormControl(),
      filterField: "Status",
      type: "text",
    },
  ],

  tola: [
    {
      id: "date",
      name: "Date",
      controls: new FormControl(),
      filterField: "date",
      type: "date",
    },
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },
    {
      id: "tolaName",
      name: "Tola Name",
      controls: new FormControl(),
      filterField: "tolaName",
      type: "text",
    },
    {
      id: "tolaCode",
      name: "Tola Code",
      controls: new FormControl(),
      filterField: "tolaCode",
      type: "text",
    },

    {
      id: "tolaAddress",
      name: "Tola Address",
      controls: new FormControl(),
      filterField: "tolaAddress",
      type: "text",
    },
    {
      id: "cityName",
      name: "City",
      controls: new FormControl(),
      filterField: "cityName",
      type: "text",
    },

    {
      id: "districtName",
      name: "District",
      controls: new FormControl(),
      filterField: "districtName",
      type: "text",
    },
    {
      id: "pincode",
      name: "Pincode",
      controls: new FormControl(),
      filterField: "pincode",
      type: "number",
    },
    {
      id: "aseName",
      name: "ASE Name",
      controls: new FormControl(),
      filterField: "aseName",
      type: "text",
    },

    {
      id: "customerName",
      name: "Customer Name",
      controls: new FormControl(),
      filterField: "customerName",
      type: "text",
    },
    {
      id: "customerMobileNumber",
      name: "Customer Mobile Num",
      controls: new FormControl(),
      filterField: "customerMobileNumber",
      type: "number",
    },
    {
      id: "customerProductName",
      name: "Customer Product Name",
      controls: new FormControl(),
      filterField: "customerProductName",
      type: "text",
    },
    {
      id: "customerProductAmount",
      name: "Customer Product Amount",
      controls: new FormControl(),
      filterField: "customerProductAmount",
      type: "number",
    },
    {
      id: "customerOtherStatus",
      name: "Customer Status",
      controls: new FormControl(),
      filterField: "customerOtherStatus",
      type: "text",
    },
    {
      id: "tolaOtherStatusName",
      name: "Tola Status",
      controls: new FormControl(),
      filterField: "tolaOtherStatusName",
      type: "text",
    },
    {
      id: "customerCollectionDay",
      name: "Collection Date",
      controls: new FormControl(),
      filterField: "customerCollectionDay",
      type: "number",
    },
  ],

  depositAmount: [
    {
      id: "date",
      name: "Date",
      controls: new FormControl(),
      filterField: "date",
      type: "date",
    },
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },
    {
      id: "depositType",
      name: "Deposit type",
      controls: new FormControl(),
      filterField: "depositType",
      type: "text",
    },
    {
      id: "depositAmount",
      name: "Deposit Amount",
      controls: new FormControl(),
      filterField: "depositAmount",
      type: "number",
    },
    {
      id: "bankName",
      name: "Bank Name",
      controls: new FormControl(),
      filterField: "bankName",
      type: "text",
    },
    {
      id: "depositBy",
      name: "Deposited By (Employee Name)",
      controls: new FormControl(),
      filterField: "depositBy",
      type: "text",
    },
  ],

  foreclosed: [
    {
      id: "date",
      name: "Date",
      controls: new FormControl(),
      filterField: "date",
      type: "date",
    },
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },
    {
      id: "customerName",
      name: "Customer Name",
      controls: new FormControl(),
      filterField: "customerName",
      type: "text",
    },
    {
      id: "tolaName",
      name: "Tola Name",
      controls: new FormControl(),
      filterField: "tolaName",
      type: "text",
    },
    {
      id: "customerMobileNumber",
      name: "Mobile Number",
      controls: new FormControl(),
      filterField: "customerMobileNumber",
      type: "text",
    },
    {
      id: "settlementAmount",
      name: "Settlement Amount",
      controls: new FormControl(),
      filterField: "settlementAmount",
      type: "number",
    },
    {
      id: "salvationValue",
      name: "Salvation Value",
      controls: new FormControl(),
      filterField: "salvationValue",
      type: "number",
    },

    {
      id: "totalAmountForeclosed",
      name: "Total Amount Foreclosed",
      controls: new FormControl(),
      filterField: "totalAmountForeclosed",
      type: "number",
    },
    {
      id: "reason",
      name: "Reason",
      controls: new FormControl(),
      filterField: "reason",
      type: "text",
    },
    {
      id: "receiveProducts",
      name: "Received Product",
      controls: new FormControl(),
      filterField: "receiveProducts",
      type: "text",
    },
  ],

  targetvsachievement: [
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },
    {
      id: "name",
      name: "Employee Name",
      controls: new FormControl(),
      filterField: "name",
      type: "text",
    },
    {
      id: "roleName",
      name: "Employee Designation",
      controls: new FormControl(),
      filterField: "roleName",
      type: "text",
    },
    {
      id: "salesTarget",
      name: "Sales Target",
      controls: new FormControl(),
      filterField: "salesTarget",
      type: "number",
    },
    {
      id: "totalCustomer",
      name: "Total Customer",
      controls: new FormControl(),
      filterField: "totalCustomer",
      type: "number",
    },
    {
      id: "totalProduct",
      name: "Total Product Sold",
      controls: new FormControl(),
      filterField: "totalProduct",
      type: "number",
    },
    {
      id: "totalProductAmount",
      name: "Total Product Amount",
      controls: new FormControl(),
      filterField: "totalProductAmount",
      type: "number",
    },
    {
      id: "achivement",
      name: "% Achivement",
      controls: new FormControl(),
      filterField: "achivement",
      type: "number",
    },
    {
      id: "areaStoreManagerName",
      name: "Area Store Manager Name",
      controls: new FormControl(),
      filterField: "areaStoreManagerName",
      type: "text",
    },
    {
      id: "storeManagerName",
      name: "Store Manager Name",
      controls: new FormControl(),
      filterField: "storeManagerName",
      type: "text",
    },
  ],

  cashSales: [
    {
      id: "date",
      name: "Date",
      controls: new FormControl(),
      filterField: "date",
      type: "date",
    },
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },

    {
      id: "customerName",
      name: "Customer Name",
      controls: new FormControl(),
      filterField: "customerName",
      type: "text",
    },

    {
      id: "customerAddress",
      name: "Customer Address",
      controls: new FormControl(),
      filterField: "customerAddress",
      type: "text",
    },
    {
      id: "customerMobileNumber",
      name: "Customer Mobile Number",
      controls: new FormControl(),
      filterField: "customerMobileNumber",
      type: "number",
    },
    {
      id: "vleUserName",
      name: "Inq. Created By",
      controls: new FormControl(),
      filterField: "vleUserName",
      type: "text",
    },
    {
      id: "createdThrough",
      name: "Created Through",
      controls: new FormControl(),
      filterField: "createdThrough",
      type: "text",
    },
    {
      id: "vleUserRole",
      name: "Inq. Creator's Role",
      controls: new FormControl(),
      filterField: "vleUserRole",
      type: "text",
    },

    {
      id: "aseUserName",
      name: "ASE",
      controls: new FormControl(),
      filterField: "aseUserName",
      type: "text",
    },
    {
      id: "smUserName",
      name: "SM",
      controls: new FormControl(),
      filterField: "smUserName",
      type: "text",
    },
    {
      id: "amUserName",
      name: "AM",
      controls: new FormControl(),
      filterField: "amUserName",
      type: "text",
    },
    {
      id: "productName",
      name: "Product Name",
      controls: new FormControl(),
      filterField: "productName",
      type: "text",
    },
    {
      id: "finalAmount",
      name: "Product Amount",
      controls: new FormControl(),
      filterField: "finalAmount",
      type: "number",
    },
    {
      id: "modeofPayment",
      name: "Mode of Payment",
      controls: new FormControl(),
      filterField: "modeofPayment",
      type: "text",
    },
  ],

  disbursement: [
    {
      id: "date",
      name: "Date",
      controls: new FormControl(),
      filterField: "date",
      type: "date",
    },
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },
    {
      id: "createdThrough",
      name: "Created Through",
      controls: new FormControl(),
      filterField: "createdThrough",
      type: "text",
    },

    {
      id: "customerName",
      name: "Customer Name",
      controls: new FormControl(),
      filterField: "customerName",
      type: "text",
    },
    {
      id: "loanNo",
      name: "Customer Code",
      controls: new FormControl(),
      filterField: "loanNo",
      type: "text",
    },
    {
      id: "tolaName",
      name: "Tola Name",
      controls: new FormControl(),
      filterField: "tolaName",
      type: "text",
    },
    {
      id: "tolaCode",
      name: "Tola Code",
      controls: new FormControl(),
      filterField: "tolaCode",
      type: "text",
    },

    {
      id: "customerMobileNumber",
      name: "Customer Mobile Num",
      controls: new FormControl(),
      filterField: "customerMobileNumber",
      type: "number",
    },
    {
      id: "age",
      name: "Age",
      controls: new FormControl(),
      filterField: "age",
      type: "number",
    },
    {
      id: "maritalStatus",
      name: "Maritial Status",
      controls: new FormControl(),
      filterField: "maritalStatus",
      type: "text",
    },
    {
      id: "occupation",
      name: "Occupation",
      controls: new FormControl(),
      filterField: "occupation",
      type: "text",
    },
    {
      id: "permenantAddress",
      name: "Permenant Address",
      controls: new FormControl(),
      filterField: "permenantAddress",
      type: "text",
    },
    {
      id: "cityName",
      name: "City/Village",
      controls: new FormControl(),
      filterField: "cityName",
      type: "text",
    },
    {
      id: "districtName",
      name: "District",
      controls: new FormControl(),
      filterField: "districtName",
      type: "text",
    },
    {
      id: "pincode",
      name: "Pincode",
      controls: new FormControl(),
      filterField: "pincode",
      type: "number",
    },
    {
      id: "houseType",
      name: "House Type",
      controls: new FormControl(),
      filterField: "houseType",
      type: "text",
    },
    {
      id: "currentAddress",
      name: "Current Address",
      controls: new FormControl(),
      filterField: "currentAddress",
      type: "text",
    },
    {
      id: "kycName",
      name: "Customer KYC Name",
      controls: new FormControl(),
      filterField: "kycName",
      type: "text",
    },
    {
      id: "kycNumber",
      name: "Customer KYC Number",
      controls: new FormControl(),
      filterField: "kycNumber",
      type: "text",
    },
    {
      id: "kyC1Name",
      name: "Customer KYC 1 Name",
      controls: new FormControl(),
      filterField: "kyC1Name",
      type: "text",
    },
    {
      id: "kyC1Number",
      name: "Customer KYC 1 Number",
      controls: new FormControl(),
      filterField: "kyC1Number",
      type: "text",
    },
    {
      id: "guarantorName",
      name: "Guarantor Name",
      controls: new FormControl(),
      filterField: "guarantorName",
      type: "text",
    },
    {
      id: "guarantorAddress",
      name: "Guarantor Address",
      controls: new FormControl(),
      filterField: "guarantorAddress",
      type: "text",
    },
    {
      id: "guarantorRelation",
      name: "Guarantor Relation",
      controls: new FormControl(),
      filterField: "guarantorRelation",
      type: "text",
    },
    {
      id: "guarantorAge",
      name: "Guarantor Age",
      controls: new FormControl(),
      filterField: "guarantorAge",
      type: "number",
    },
    {
      id: "guarantorHousetype",
      name: "Guarantor House type",
      controls: new FormControl(),
      filterField: "guarantorHousetype",
      type: "text",
    },
    {
      id: "guarantorOccupation",
      name: "Guarantor Occupation",
      controls: new FormControl(),
      filterField: "guarantorOccupation",
      type: "text",
    },
    {
      id: "guarantorKYCName",
      name: "Guarantor KYC Name",
      controls: new FormControl(),
      filterField: "guarantorKYCName",
      type: "text",
    },
    {
      id: "guarantorKYCNumber",
      name: "Guarantor KYC Number",
      controls: new FormControl(),
      filterField: "guarantorKYCNumber",
      type: "text",
    },
    {
      id: "guarantorKYC1Name",
      name: "Guarantor KYC 1 Name",
      controls: new FormControl(),
      filterField: "guarantorKYC1Name",
      type: "text",
    },
    {
      id: "guarantorKYC1Number",
      name: "Guarantor KYC 1 Number",
      controls: new FormControl(),
      filterField: "guarantorKYC1Number",
      type: "text",
    },

    {
      id: "securityChequeCount",
      name: "Security Cheque Count",
      controls: new FormControl(),
      filterField: "securityChequeCount",
      type: "text",
    },
    {
      id: "bankName",
      name: "Bank Name",
      controls: new FormControl(),
      filterField: "bankName",
      type: "text",
    },
    {
      id: "productName",
      name: "Product Name",
      controls: new FormControl(),
      filterField: "productName",
      type: "text",
    },
    {
      id: "productAmount",
      name: "Product Amount",
      controls: new FormControl(),
      filterField: "productAmount",
      type: "number",
    },
    {
      id: "installmentTenure",
      name: "Installment Tenure",
      controls: new FormControl(),
      filterField: "installmentTenure",
      type: "number",
    },
    {
      id: "installmentAmount",
      name: "Installment Amount",
      controls: new FormControl(),
      filterField: "installmentAmount",
      type: "number",
    },
    {
      id: "advanceAmount",
      name: "Advance Amount",
      controls: new FormControl(),
      filterField: "advanceAmount",
      type: "number",
    },
    {
      id: "advanceAmountPaymentMode",
      name: "Payment Mode",
      controls: new FormControl(),
      filterField: "advanceAmountPaymentMode",
      type: "text",
    },
    {
      id: "advanceAmountTransactionId",
      name: "Transaction ID",
      controls: new FormControl(),
      filterField: "advanceAmountTransactionId",
      type: "text",
    },
    {
      id: "processingFee",
      name: "Processing Fee",
      controls: new FormControl(),
      filterField: "processingFee",
      type: "number",
    },
    {
      id: "processingFeePaymentMode",
      name: "Processing Fee Payment Mode",
      controls: new FormControl(),
      filterField: "processingFeePaymentMode",
      type: "text",
    },
    {
      id: "processingFeeTransactionID",
      name: "Transaction ID",
      controls: new FormControl(),
      filterField: "processingFeeTransactionID",
      type: "text",
    },
    // {
    //   id: "name",
    //   name: "Safeguard Instrument Amount if any",
    //   controls: new FormControl(),
    //   filterField: "name",
    //   type: "text",
    // },
    {
      id: "remark",
      name: "Mobile IMEI Number",
      controls: new FormControl(),
      filterField: "remark",
      type: "text",
    },
    {
      id: "statusName",
      name: "Customer Status",
      controls: new FormControl(),
      filterField: "statusName",
      type: "text",
    },
    {
      id: "createdBy",
      name: "Created By",
      controls: new FormControl(),
      filterField: "createdBy",
      type: "text",
    },
    {
      id: "createdByDesignation",
      name: "Customer Created By Designation",
      controls: new FormControl(),
      filterField: "createdByDesignation",
      type: "text",
    },
    {
      id: "ase",
      name: "ASE",
      controls: new FormControl(),
      filterField: "ase",
      type: "text",
    },
    {
      id: "se",
      name: "SE",
      controls: new FormControl(),
      filterField: "se",
      type: "text",
    },
    {
      id: "sm",
      name: "SM",
      controls: new FormControl(),
      filterField: "sm",
      type: "text",
    },
    {
      id: "am",
      name: "AM",
      controls: new FormControl(),
      filterField: "am",
      type: "text",
    },
    {
      id: "collectionDay",
      name: "Collection Day",
      controls: new FormControl(),
      filterField: "collectionDay",
      type: "number",
    },
    {
      id: "firstInstallmentDate",
      name: "First Installment Date",
      controls: new FormControl(),
      filterField: "firstInstallmentDate",
      type: "date",
    },
    {
      id: "lastInstallmentDate",
      name: "Last Installment Date",
      controls: new FormControl(),
      filterField: "lastInstallmentDate",
      type: "date",
    },
  ],

  attendance: [
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },

    {
      id: "employeeName",
      name: "Employee Name",
      controls: new FormControl(),
      filterField: "employeeName",
      type: "text",
    },
    {
      id: "employeeDesignation",
      name: "Employee Designation",
      controls: new FormControl(),
      filterField: "employeeDesignation",
      type: "text",
    },
    {
      id: "attedanceDate",
      name: "Attedance Date",
      controls: new FormControl(),
      filterField: "attedanceDate",
      type: "date",
    },
    {
      id: "dayStartTime",
      name: "Day Start Time",
      controls: new FormControl(),
      filterField: "dayStartTime",
      type: "text",
    },

    {
      id: "inLongitude",
      name: "Longitude",
      controls: new FormControl(),
      filterField: "inLongitude",
      type: "number",
    },
    {
      id: "inLatitude",
      name: "Latitude",
      controls: new FormControl(),
      filterField: "inLatitude",
      type: "number",
    },
    {
      id: "inAddress",
      name: "Address",
      controls: new FormControl(),
      filterField: "inAddress",
      type: "text",
    },
    {
      id: "dayEndTime",
      name: "Day End Time",
      controls: new FormControl(),
      filterField: "dayEndTime",
      type: "text",
    },
    {
      id: "outLongitude",
      name: "Longitude",
      controls: new FormControl(),
      filterField: "outLongitude",
      type: "text",
    },
    {
      id: "outLatitude",
      name: "Latitude",
      controls: new FormControl(),
      filterField: "outLatitude",
      type: "text",
    },
    {
      id: "outAddress",
      name: "Address",
      controls: new FormControl(),
      filterField: "outAddress",
      type: "text",
    },
    {
      id: "workingTime",
      name: "Working Time",
      controls: new FormControl(),
      filterField: "workingTime",
      type: "text",
    },
  ],


  collectionIncentive: [
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },
    {
      id: "designation",
      name: "Employee Designation",
      controls: new FormControl(),
      filterField: "designation",
      type: "text",
    },
    {
      id: "employeeName",
      name: "Employee Name",
      controls: new FormControl(),
      filterField: "employeeName",
      type: "text",
    },
    {
      id: "totalCollectionAmountCustomer",
      name: "Total Collection Amount Customer",
      controls: new FormControl(),
      filterField: "totalCollectionAmountCustomer",
      type: "number",
    },
    {
      id: "totalCollectionEMIs",
      name: "Total Collection Emis",
      controls: new FormControl(),
      filterField: "totalCollectionEMIs",
      type: "number",
    },
    {
      id: "totalCollectionAmountValue",
      name: "Total Collection Amount Value",
      controls: new FormControl(),
      filterField: "totalCollectionAmountValue",
      type: "number",
    },

    {
      id: "collectedAmountCustomer",
      name: "Collected Amount Customer",
      controls: new FormControl(),
      filterField: "collectedAmountCustomer",
      type: "number",
    },
    {
      id: "collectedAmountEMIs",
      name: "Collection Amount EMIs",
      controls: new FormControl(),
      filterField: "collectedAmountEMIs",
      type: "number",
    },

    {
      id: "collectedAmountValue",
      name: "Collected Amount Value",
      controls: new FormControl(),
      filterField: "collectedAmountValue",
      type: "number",
    },
    {
      id: "perCustomerIncentive",
      name: "Per EMI Incentive",
      controls: new FormControl(),
      filterField: "perCustomerIncentive",
      type: "number",
    },
    {
      id: "totalIncentive",
      name: "Total Incentive",
      controls: new FormControl(),
      filterField: "totalIncentive",
      type: "number",
    },
    {
      id: "perCollection",
      name: "% Collection (Colleted Amount /Collection Amount )",
      controls: new FormControl(),
      filterField: "perCollection",
      type: "number",
    },

  ],

  collectiondonebutnotapproved: [
    {
      id: "collectionDate",
      name: "Date",
      controls: new FormControl(),
      filterField: "collectionDate",
      type: "date",
    },
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },
    {
      id: "customerName",
      name: "Customer Name",
      controls: new FormControl(),
      filterField: "customerName",
      type: "text",
    },
    {
      id: "tolaName",
      name: "Tola Name",
      controls: new FormControl(),
      filterField: "tolaName",
      type: "text",
    },
    {
      id: "customerMobileNumber",
      name: "Customer Mobile Num",
      controls: new FormControl(),
      filterField: "customerMobileNumber",
      type: "number",
    },
    {
      id: "customerAddress",
      name: "Customer Address",
      controls: new FormControl(),
      filterField: "customerAddress",
      type: "text",
    },
    {
      id: "cityName",
      name: "City",
      controls: new FormControl(),
      filterField: "cityName",
      type: "text",
    },
    {
      id: "pincode",
      name: "Pincode",
      controls: new FormControl(),
      filterField: "pincode",
      type: "number",
    },
    {
      id: "aseName",
      name: "ASE Name",
      controls: new FormControl(),
      filterField: "aseName",
      type: "text",
    },
    {
      id: "smName",
      name: "SM Name",
      controls: new FormControl(),
      filterField: "smName",
      type: "text",
    },
    {
      id: "amName",
      name: "AM Name",
      controls: new FormControl(),
      filterField: "amName",
      type: "text",
    },
    {
      id: "collectionDate",
      name: "Collection Date",
      controls: new FormControl(),
      filterField: "collectionDate",
      type: "date",
    },
    {
      id: "lastUpdatedDate",
      name: "Last Updated Date",
      controls: new FormControl(),
      filterField: "lastUpdatedDate",
      type: "date",
    },
    {
      id: "collectionAmount",
      name: "Received Amount",
      controls: new FormControl(),
      filterField: "collectionAmount",
      type: "number",
    },
    {
      id: "collectionStatusName",
      name: "Collection Status",
      controls: new FormControl(),
      filterField: "collectionStatusName",
      type: "text",
    },
    {
      id: "modeOfPayment",
      name: "Mode of Payment",
      controls: new FormControl(),
      filterField: "modeOfPayment",
      type: "text",
    },
    {
      id: "transactionID",
      name: "Transaction ID",
      controls: new FormControl(),
      filterField: "transactionID",
      type: "text",
    },
  ],

  productIncentive: [
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    {
      id: "storeName",
      name: "Store Name",
      controls: new FormControl(),
      filterField: "storeName",
      type: "text",
    },
    {
      id: "employeeName",
      name: "Employee Name",
      controls: new FormControl(),
      filterField: "employeeName",
      type: "text",
    },
    {
      id: "designation",
      name: "Employee designation",
      controls: new FormControl(),
      filterField: "designation",
      type: "text",
    },

    {
      id: "productName",
      name: "Product Name",
      controls: new FormControl(),
      filterField: "statproductNameeName",
      type: "text",
    },
    {
      id: "emiProductQTY",
      name: "Product QTY (Disbursement)",
      controls: new FormControl(),
      filterField: "emiProductQTY",
      type: "number",
    },
    {
      id: "emiProductAmount",
      name: "Product Amount (Disbursement)",
      controls: new FormControl(),
      filterField: "emiProductAmount",
      type: "number",
    },
    // {
    //   id: "emiPerProductIncentive",
    //   name: "Emi Per Product Incentive",
    //   controls: new FormControl(),
    //   filterField: "emiPerProductIncentive",
    //   type: "number",
    // },
    {
      id: "emiIncentiveAmount",
      name: "Incentive Amount (Disbursement)",
      controls: new FormControl(),
      filterField: "emiIncentiveAmount",
      type: "number",
    },
    {
      id: "cashProductQTY",
      name: "Product QTY  (Cash Sales)",
      controls: new FormControl(),
      filterField: "cashProductQTY",
      type: "number",
    },
    {
      id: "cashProductAmount",
      name: "Product Amount  (Cash Sales) ",
      controls: new FormControl(),
      filterField: "cashProductAmount",
      type: "number",
    },
    // {
    //   id: "cashPerProductIncentive",
    //   name: "Cash Per Product Incentive",
    //   controls: new FormControl(),
    //   filterField: "cashPerProductIncentive",
    //   type: "number",
    // },
    {
      id: "cashIncentiveAmount",
      name: "Incentive Amount (Cash Sales)",
      controls: new FormControl(),
      filterField: "cashIncentiveAmount",
      type: "number",
    },
    {
      id: "totalIncentive",
      name: "Total Incentive",
      controls: new FormControl(),
      filterField: "totalIncentive",
      type: "number",
    },
  ],
  targetIncentive: [
    {
      id: "stateName",
      name: "State",
      controls: new FormControl(),
      filterField: "stateName",
      type: "text",
    },
    // {
    //   id: "storeName",
    //   name: "Store Name",
    //   controls: new FormControl(),
    //   filterField: "storeName",
    //   type: "text",
    // },
    {
      id: "employeeName",
      name: "Employee Name",
      controls: new FormControl(),
      filterField: "employeeName",
      type: "text",
    },
    // {
    //   id: "designation",
    //   name: "Employee designation",
    //   controls: new FormControl(),
    //   filterField: "designation",
    //   type: "text",
    // },

    {
      id: "productName",
      name: "Product Name",
      controls: new FormControl(),
      filterField: "statproductNameeName",
      type: "text",
    },
    {
      id: "emiTotalProductSold",
      name: " Product QTY (Disbursement)",
      controls: new FormControl(),
      filterField: "emiTotalProductSold",
      type: "number",
    },
    {
      id: "emiTotalProductAmount",
      name: "Product Amount (Disbursement) ",
      controls: new FormControl(),
      filterField: "emiTotalProductAmount",
      type: "number",
    },
    {
      id: "emiTotalIncentiveAmount",
      name: "Incentive Amount (Disbursement) ",
      controls: new FormControl(),
      filterField: "emiTotalIncentiveAmount",
      type: "number",
    },
  ]
};
