import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LMSAnimations } from "app/shared/animations/LMS-animations";
import { PageSizeOptions } from "app/shared/config/pagination.config";
import { IPageAction } from "app/shared/models/shared/page-action.interface";
import { IPaginationSpecsDto } from "app/shared/models/shared/querySpecs.model";
import * as moment from "moment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { reportcolumn } from "./report-column.config";

@Component({
  selector: "lms-report-table",
  templateUrl: "./report-table.component.html",
  styleUrls: ["./report-table.component.scss"],
  animations: LMSAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTableComponent implements OnInit, AfterViewInit, OnDestroy {
  // String / string[]
  title: string;
  displayedColumns: string[];
  apiEndPointList: string;
  apiEndPointDelete: string;
  imageUrl: string;
  defImageUrl: string;
  // public key: string;

  // Number / Number[]
  currentPage: number;
  pageSize: number;
  activeTotalRecords: number;
  activePageSize: number;
  pageSizeOptions: number[] = PageSizeOptions;

  // Interface / class
  pageActions: IPageAction[] = [];
  pageSpecs: IPaginationSpecsDto;

  // DataSource
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  // BehaviorSubject / Observables / Subject
  public noDataMsg: BehaviorSubject<string>;
  public noDataMsg$: Observable<string>;
  destroy$: Subject<any> = new Subject();

  // FormControls
  filters: FormControl[] = [];
  columnsDef: any;
  GridRows: any[] = [];

  // ViewChild
  @ViewChild("productTable") listSort: MatSort;
  @ViewChild("paginator") paginator: MatPaginator;

  constructor() {}

  @Input() data: any;
  @Input() keyValue: string;
  ngOnInit(): void {
    // Get Columns Key from columns config file
    const obj = Object.keys(reportcolumn);
    // set display column if title and columns key are same
    obj.forEach((key) => {
      if (this.keyValue.trim() == key.trim()) {
        this.columnsDef = reportcolumn[key];
        this.displayedColumns = this.columnsDef.map((item) => item.id);
      }
    });
    this.dataSource = this.data;
    this.activeTotalRecords = this.dataSource.data.length;
    this.activePageSize = 10;
    this.activeTotalRecords = this.dataSource.data.length;

    this._onFormChange();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.listSort;
    this.pageSize = this.dataSource.data.length;
    this.dataSource.paginator = this.paginator;
  }
  ngOnDestroy(): void {
    this.dataSource.data = [];
    this.dataSource = new MatTableDataSource();
  }

  public sort() {
    this.columnsDef.forEach((el) => {
      this.dataSource.sortingDataAccessor = (element, property) => {
        switch (property) {
          case el.id.toString():
            return element[property];

          default:
            return element[property];
        }
      };
    });
  }

  private _onFormChange(): void {
    this.columnsDef.forEach((el) => {
      if (el?.controls && el?.type == "text") {
        // Filter for text value
        el.controls.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
          .subscribe((val) => {
            if (this.dataSource.data.length && val) {
              this.dataSource.data = this.GridRows.filter((a) =>
                (a[el.id]?.toString().trim().toLowerCase() ?? "").includes(val?.toString().trim().toLowerCase() ?? "")
              );
            } else {
              this.dataSource.data = this.GridRows;
            }
          });
      }
      if (el?.controls && el?.type == "number") {
        // Filter for text value
        el.controls.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
          .subscribe((val) => {
            if (this.dataSource.data.length && val) {
              this.dataSource.data = this.GridRows.filter((a: any) =>
                (a[el.id]?.toString().trim().toLowerCase() ?? "0").includes(val?.toString().trim().toLowerCase() ?? "0")
              );
            } else {
              this.dataSource.data = this.GridRows;
            }
          });
      }
      if (el?.controls && (el?.type == "boolean" || el?.type == "approvalStatus")) {
        // Filter for boolean value
        el.controls.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
          .subscribe((val) => {
            if (this.dataSource.data.length && val) {
              this.dataSource.data = this.GridRows.filter((a: any) =>
                a[el.id].toString().toLowerCase().includes(val.toString().toLowerCase())
              );
            } else {
              this.dataSource.data = this.GridRows;
            }
          });
      }
      if (el?.type == "date") {
        el.controls.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
          .subscribe((val) => {
            if (this.dataSource.data.length && val) {
              this.dataSource.data = this.GridRows.filter((a: any) =>
                // a[el.id].toString().includes(moment(val).format("YYYY-MM-DD").toString())
                (a[el.id]?.toString().trim().toLowerCase() ?? "").includes(
                  moment(val).format("YYYY-MM-DD")?.toString().trim().toLowerCase() ?? ""
                )
              );
            } else {
              this.dataSource.data = this.GridRows;
            }
          });
      }
    });
  }
}
