import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "./auth/auth.service";

export interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
export interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

export interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [
    {
      name: "Home",
      type: "link",
      icon: "dashboard",
      state: "home",
    },

    // },
  ];

  constructor(private _authService: AuthService) {
    this._checkRolePermissionForMenu();
  }

  private _checkRolePermissionForMenu(): void {
    let user;
    this._authService.userProfile$.subscribe((loginUser) => {
      user = loginUser;
    });

    if (user.authKeys.includes("Inquiry-list")) {
      this.iconMenu.push({
        name: "Inquiries",
        type: "link",
        icon: "question_answer",
        state: "transactions/inquiries",
      });
    }

    if (user.authKeys.includes("Tola-list")) {
      this.iconMenu.push({
        name: "Tolas",
        type: "link",
        icon: "groups",
        state: "transactions/tola",
      });
    }

    if (user.authKeys.includes("CashSales-list")) {
      this.iconMenu.push({
        name: "Cash Sales",
        type: "link",
        icon: "account_balance",
        state: "transactions/cashsales",
      });
    }

    if (user.authKeys.includes("Collection-list")) {
      this.iconMenu.push({
        name: "Collection / Receipts",
        type: "link",
        icon: "receipt",
        state: "transactions/collection",
      });
    }

    if (user.authKeys.includes("Collection-list")) {
      this.iconMenu.push({
        name: "Pending Approval",
        type: "link",
        icon: "pending",
        state: "transactions/collection/pending-approval",
      });
    }

    if (user.authKeys.includes("Deposit-list")) {
      this.iconMenu.push({
        name: "Deposits",
        type: "link",
        icon: "credit_score",
        state: "transactions/deposit",
      });
    }

    if (user.authKeys.includes("CustomerHistory-list")) {
      this.iconMenu.push({
        name: "Customer History",
        type: "link",
        icon: "manage_search",
        state: "transactions/customer-history",
      });
    }

    if (user.authKeys.includes("Foreclose-list")) {
      this.iconMenu.push({
        name: "Foreclose",
        type: "link",
        icon: "closed_caption_disabled",
        state: "transactions/foreclose",
      });
    }

    if (user.authKeys.includes("SalesTarget-list")) {
      this.iconMenu.push({
        name: "Sales Target Entries",
        type: "link",
        icon: "track_changes",
        state: "transactions/salestargetentries",
      });
    }

    this.iconMenu.push({
      name: "Reports",
      type: "link",
      icon: "report",
      state: "reports",
    });
  }

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange() {
    this.menuItems.next(this.iconMenu);
  }
}
