import { Component, OnInit, Input } from "@angular/core";
import { ThemeService } from "../../services/theme.service";
import { LayoutService } from "../../services/layout.service";
import { AuthService } from "app/shared/services/auth/auth.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { IUserProfileDto } from "app/shared/models/auth/user.profile.interface";
import { environment } from "environments/environment";

@Component({
  selector: "app-header-side",
  templateUrl: "./header-side.template.html",
  styleUrls: ["./header-side.component.scss"],
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;

  public initials: string = "";
  private _destroy$: Subject<any>;
  public userProfile: IUserProfileDto = null;

  public lmsThemes;
  public layoutConf: any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    private _authService: AuthService
  ) {
    this._destroy$ = new Subject();

    this._authService.userProfile$
      .pipe(takeUntil(this._destroy$))
      .subscribe((val) => {
        if (val) {
          this.userProfile = val;
          if (val.imageUploadPath != null)
            this.initials = environment.domain + val.imageUploadPath;
          else
            this.initials = "../../../../../../assets/images/avatars/default-image.png";
          // val.name.split(" ").forEach((s) => (this.initials += s[0].toUpperCase()));

          //Extract user countries and default country
        }
      });
  }
  ngOnInit() {
    this.lmsThemes = this.themeService.lmsThemes;
    this.layoutConf = this.layout.layoutConf;
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === "closed") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full",
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === "compact") {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: "full",
          sidebarCompactToggle: false,
        },
        { transitionClass: true }
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: "compact",
        sidebarCompactToggle: true,
      },
      { transitionClass: true }
    );
  }

  logout(): void {
    this._authService.logout();
  }
}
