//#region import
import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute } from "@angular/router";
import { LMSAnimations } from "app/shared/animations/LMS-animations";
import { API_ENDPOINTS } from "app/shared/config/api-endpoints.config";
import { DefaultPageSize, PageSizeOptions } from "app/shared/config/pagination.config";
import { OrderBySpecs } from "app/shared/models/shared/orderBySpecs.model";
import { IPageAction } from "app/shared/models/shared/page-action.interface";
import { IFilterConditions, IFilterRule } from "app/shared/models/shared/query.filter.interface";
import { IPaginationSpecsDto, PaginsationSpecsDto, QuerySpecs } from "app/shared/models/shared/querySpecs.model";
import { AlertsService } from "app/shared/services/alerts/alerts.service";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { AuthService } from "app/shared/services/auth/auth.service";
import { HttpService } from "app/shared/services/http/http.service";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { columns } from "./colum.config";

let DefaultFilter: IFilterRule[] = [];

const DefaultPagination: PaginsationSpecsDto = {
  PaginationRequired: true,
  Limit: 10,
  Page: 1,
};

const DefaultSortOrder: OrderBySpecs[] = [{ field: "CreatedDate", direction: "desc" }];
const DefaultDisplayColumn: string[] = [];

const returnUrl: string = "settings";

// #endregion configuration

@Component({
  selector: "lms-list-page",
  templateUrl: "./list-page.component.html",
  styleUrls: ["./list-page.component.scss"],
  providers: [DatePipe],
  animations: LMSAnimations,
})
export class ListPageComponent implements OnInit {
  // String / string[]
  title: string;
  displayedColumns: string[];
  apiEndPointList: string;
  apiEndPointDelete: string;
  imageUrl: string;
  defImageUrl: string;
  public key: string;

  // Boolean
  canDelete: boolean;
  canADD: boolean;
  canEdit: boolean;

  // Number / Number[]
  currentPage: number;
  pageSize: number;
  activeTotalRecords: number;
  activePageSize: number;
  pageSizeOptions: number[] = PageSizeOptions;

  // Interface / class
  pageActions: IPageAction[] = [];
  pageSpecs: IPaginationSpecsDto;
  Filters: IFilterConditions;

  // DataSource
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  // BehaviorSubject / Observables / Subject
  public noDataMsg: BehaviorSubject<string>;
  public noDataMsg$: Observable<string>;
  private _querySpecs: BehaviorSubject<QuerySpecs>;
  private _querySpecs$: Observable<QuerySpecs>;
  destroy$: Subject<any> = new Subject();

  // FormControls
  filters: FormControl[] = [];
  columnsDef: any;

  public get activeCurrentPage(): number {
    return this._querySpecs.value.PaginationSpecs.Page - 1;
  }

  // ViewChild
  @ViewChild("MatSort") MatSort: MatSort;
  @ViewChild("activePaginator") paginatorActive: MatPaginator;

  //#endregion Public variables

  //#region constructor
  // ---------------------------------------------------------------------------------------------------------------
  // @ Constructor
  // ---------------------------------------------------------------------------------------------------------------
  constructor(
    private _dataService: HttpService,
    private _router: Router,
    private _confirmService: AppConfirmService,
    private _activatedRoute: ActivatedRoute,
    private _alertService: AlertsService,
    private _datepipe: DatePipe  ) {
    // Get Deta from routing
    (this.canADD = true), (this.canDelete = true), (this.canEdit = true);
    let data = this._activatedRoute.snapshot.data;
    // Get Title From routing and set in title
    this.title = data.title;
    this.key = data?.key;
    this.imageUrl = environment.domain;
    this.defImageUrl = "../../../../../assets/images/avatars/default-image.png";

    // Get Columns Key from columns config file
    const obj = Object.keys(columns);

    // set display column if title and columns key are same
    obj.forEach((key) => {
      if (this.key.trim() == key.trim()) {
        this.columnsDef = columns[key];
        this.displayedColumns = this.columnsDef.map((item) => item.id);
        this.apiEndPointList = API_ENDPOINTS[key].list; // API for List

        this.apiEndPointDelete = API_ENDPOINTS[key].base; // API for Delete
      }
    });

    this.activePageSize = DefaultPageSize;

    this.activeTotalRecords = 0;

    //Configure page actions
    this._configurePageActions();

    //configure no-data message
    this.noDataMsg = new BehaviorSubject("Loading...");
    this.noDataMsg$ = this.noDataMsg.asObservable();

    this._querySpecs = new BehaviorSubject(
      QuerySpecs.BuildQuery(DefaultFilter, DefaultPagination, DefaultSortOrder, DefaultDisplayColumn)
    );
    this._querySpecs$ = this._querySpecs.asObservable();

    // Search Field Value change
    this._observeQueryChanges();
  }

  ngOnInit() {
    //subscribe to filter changes
    this._onSearchChanges();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.MatSort;
    this.dataSource.paginator = this.paginatorActive;
  }

  //ondestroy
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();

    this._querySpecs.next(null);
    this._querySpecs.complete();
    this._resetFilters();
    DefaultPagination.Limit = 10;
    DefaultPagination.Page = 1;
    DefaultPagination.PaginationRequired = true;
    DefaultFilter = [];
  }
  //#endregion Lifecycle hooks

  //#region Public Methods
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  // Page Change Method
  pageChanged(event: PageEvent) {
    this._querySpecs.next(this._querySpecs.value.UpdatePagination(event));
  }

  // Sorting
  sortData(sort: Sort) {
    if (!sort.active) {
      return;
    }
    let sortField: string;
    let query = this._querySpecs.value;

    if ((sort.direction as string) == "") {
      query.OrderBySpecs = DefaultSortOrder;
    } else {
      this.columnsDef.forEach((el) => {
        switch (sort.active) {
          case el.id:
            sortField = el?.filterField;
            break;
          default:
            break;
        }
      });
      query.UpdateOrderBy(sortField, sort.direction);
    }

    this._querySpecs.next(query);
  }

  /**
   * Delete record
   * @param id : record Id
   * @param name: record Name
   */
  delete(obj) {
    //Confirm action
    this._confirmService
      .confirmDelete()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          var id: string;
          if (this.key == "pinCode") {
            id = obj.pincode;
          } else {
            id = obj.id;
          }
          this._dataService
            .deleteData(id, this.apiEndPointDelete)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
              if (res.success) {
                this._getData(this._querySpecs.value);
                if (this.key == "pinCode") {
                  this._alertService.raiseSuccessAlert(`Pin Code ${obj.pincode}  deleted successfully`);
                } else if (this.key == "loanType") {
                  this._alertService.raiseSuccessAlert(`Loan Type ${obj?.type}  deleted successfully`);
                } else if (this.key == "approvalWorkflow") {
                  this._alertService.raiseSuccessAlert(`Loan Type ${obj?.documentName}  deleted successfully`);
                } else {
                  this._alertService.raiseSuccessAlert(`Record ${obj?.name}  deleted successfully`);
                }
              }
            });
        }
        (err) => {
          this._alertService.raiseErrorAlert(err.message);
        };
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Observe Query Changes
   * on change Query call List API
   */
  private _observeQueryChanges(): void {
    this._querySpecs$.pipe(takeUntil(this.destroy$)).subscribe((specs) => {
      if (specs) {
        this._getData(specs);
      }
    });
  }

  /**
   * configure Page Actions
   */
  private _configurePageActions(): void {
    // back
    const back = () => {
      this._router.navigate([returnUrl]);
    };

    // Refresh

    // Add
    const add = () => {
      this.columnsDef.forEach(() => {
        this._router.navigate([this._router.url + "/new"]);
      });
    };
    this.pageActions = [
      {
        caption: "Back",
        name: "back",
        icon: "arrow_back",
        action: back,
      },
      // {
      //   caption: "Refresh",
      //   name: "refresh",
      //   icon: "refresh",
      //   action: refresh,
      // },
      {
        caption: "Add",
        name: "add",
        icon: "add",
        action: add,
      },
    ];
  }

  /**
   * _getData
   * Call list API Using Service
   * @param querySpecs : QuerySpecs
   */
  private _getData(querySpecs: QuerySpecs): void {
    this._dataService
      .getPageList(querySpecs, this.apiEndPointList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.success) {
          this.dataSource.data = res.data.items;
          this.activeTotalRecords = res.data.totalCount;
          this.activePageSize = res.data.pageSize;
          if (res.data.items.length == 0) {
            this.noDataMsg.next("No records found");
          }
        } else {
          this.noDataMsg.next("Unable to fetch data from server");
        }
      });
  }

  /**
   * OnChange FormControl Filter data
   */
  private _onSearchChanges(): void {
    /* search value observers */
    this.columnsDef.forEach((el) => {
      if (el?.controls && el?.type == "text") {
        // Filter for text value
        el.controls.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
          .subscribe((val) => {
            this._querySpecs.next(this._querySpecs.value.UpdateFilter(el.filterField, val));
          });
      } else if (el?.controls && el?.type == "number") {
        // Filter for text value
        el.controls.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
          .subscribe((val) => {
            this._querySpecs.next(this._querySpecs.value.UpdateFilter(el.filterField, val, "eq"));
          });
      } else if (el?.controls && (el?.type == "boolean" || el?.type == "approvalStatus")) {
        // Filter for boolean value
        el.controls.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
          .subscribe((val) => {
            this._querySpecs.next(this._querySpecs.value.UpdateFilter(el.filterField, val, "eq"));
          });
      } else if (el?.type == "date") {
        el.controls.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
          .subscribe((val) => {
            let val1 = this._datepipe.transform(val, "yyyy-MM-dd HH:mm:ss");
            let val2 = this._datepipe.transform(val, "yyyy-MM-dd 23:59:59");
            this._querySpecs.next(this._querySpecs.value.UpdateFilter2(el.filterField, val1, val2));
          });
      } else if (el?.additional) {
        el.controls.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
          .subscribe((val) => {
            this._querySpecs.next(this._querySpecs.value.UpdateAdvanceFilter(el.filterField, val));
          });
      }
    });
  }

  /**
   * Reset FormControl
   */
  private _resetFilters(): void {
    // Filter Reset
    this.columnsDef.forEach((el) => {
      // Reset FormControl value
      if (el.controls?.value) {
        el.controls.reset();
      }
    });
  }
}
